import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MyThemeContext } from "../../context/theme-context";
import { SettingsContext } from "../../context/settings-context";
import { Web3Context } from "../../context/web3-context";

import { AppBar, Box, Toolbar, Typography, IconButton, Tabs, Tab, Avatar, useMediaQuery, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";

import DrawerComponent from "./DrawerComponent";
import SettingsMenu from "./SettingsMenu";
import WalletsMenu from "./WalletsMenu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsightsIcon from "@mui/icons-material/Insights";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Donate from "../Common/Donate";

const links = [
	{ id: 0, name: "Dashboard", url: "/dashboard", icon: <DashboardIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 1, name: "Stats", url: "/stats", icon: <InsightsIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 2, name: "Market", url: "/market", icon: <LocalGroceryStoreIcon sx={{ width: 45, height: 45 }} />, hide: false },
	// { id: 3, name: "Invests", url: "/invests", icon: <SavingsIcon sx={{ width: 45, height: 45 }} />, hide: false },
	// { id: 4, name: "Community", url: "/community", icon: <PeopleIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 8, name: "API Metrics", url: "/traffic", icon: <NetworkCheckIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 5, name: "Settings", url: "/settings", icon: <SettingsIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 7, name: "Help", url: "/help", icon: <HelpOutlineIcon sx={{ width: 45, height: 45 }} />, hide: false },
	{ id: 6, name: "About", url: "/about", icon: <InfoIcon sx={{ width: 45, height: 45 }} />, hide: false },
	// { id: 7, name: "Training", url: "/training", icon: <ModelTrainingIcon sx={{ width: 45, height: 45 }} />, hide: false },
];

function Header() {
	const { themeContext } = useContext(MyThemeContext);
	const { blockchainsClient, settingsWallet, FRONT_END_NAME, FRONT_END_VERSION } = useContext(SettingsContext);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openSettingsMenu, setOpenSettingsMenu] = useState(null);
	const [openWalletsMenu, setOpenWalletsMenu] = useState(null);
	const [openDonate, setOpenDonate] = useState(false);
	const { account, connect, disconnect, active } = useContext(Web3Context);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));
	// console.log("isMatch", isMatch,'valueTab',window.location.pathname);

	const handleWalletsClick = (event) => {
		setOpenWalletsMenu(event);
		setOpenSettingsMenu(null);
	};
	const handleSettingsClick = (event) => {
		setOpenSettingsMenu(event);
		setOpenWalletsMenu(null);
	};
	const handleSettingsClose = (event) => {
		setOpenSettingsMenu(null);
		setOpenWalletsMenu(null);
	};

	// const handleConnectWeb3 = async () => {
	// 	if (!web3 || !account) {
	// 		await connect();
	// 	}

	// 	if (web3 && account) {
	// 		alert("handleConnectWeb3 successful!");
	// 	}
	// };

	if (!blockchainsClient) return <></>;
	if (!settingsWallet) return <></>;
	const chainUsed = settingsWallet ? blockchainsClient[settingsWallet.selectedBlockchain] : blockchainsClient["Gnosis"];

	return (
		<Box sx={{ width: 1, height: 64 }}>
			<Donate open={openDonate} setOpen={setOpenDonate} />
			<DrawerComponent openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} links={links} />
			<SettingsMenu open={openSettingsMenu} menuClose={handleSettingsClose} />
			<WalletsMenu open={openWalletsMenu} menuClose={handleSettingsClose} />
			<AppBar>
				<Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
					{isMatch && (
						<IconButton color='inherit' onClick={() => setOpenDrawer(true)}>
							<MenuIcon />
						</IconButton>
					)}

					<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} onClick={() => setOpenDrawer(true)} />

					<Typography variant='h6' color='inherit' sx={{ ml: 1 }} onClick={() => setOpenDrawer(true)}>
						{FRONT_END_NAME}
						{/* {FRONT_END_VERSION} */}
					</Typography>

					{!isMatch && (
						<Tabs value={window.location.pathname} textColor='inherit' indicatorColor='secondary' sx={{ ml: "auto" }}>
							{links
								// .filter((link) => link.hide !== true)
								.map(
									(link, index) =>
										link.hide !== true && <Tab key={index} tabIndex={index} component={Link} label={link.name} value={link.url} to={link.url} />
								)}
						</Tabs>
					)}

					{/* <ConnectButton /> */}

					{chainUsed ? (
						<IconButton sx={{ ml: "auto" }}>
							<Avatar alt={chainUsed.name} src={chainUsed.image} fontSize='small' sx={{ width: 30, height: 30 }} />
						</IconButton>
					) : (
						<IconButton sx={{ ml: "auto" }}>
							<Avatar alt='Logo' src='/logo192.png' />
						</IconButton>
					)}
					<IconButton color='inherit' onClick={(e) => handleWalletsClick(e)}>
						<AccountBalanceWalletIcon sx={{ width: 25, height: 25 }} />
					</IconButton>
					<IconButton color='inherit' onClick={() => setOpenDonate(true)}>
						<CardGiftcardIcon sx={{ width: 25, height: 25 }} />
					</IconButton>
					<IconButton color='inherit' onClick={(e) => handleSettingsClick(e)}>
						<SettingsIcon sx={{ width: 25, height: 25 }} />
					</IconButton>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default Header;

import React, { useContext, useState, useEffect } from "react";
import { SettingsContext, balanceWallets, settingsWallet } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";
import { SnackbarContext } from "../../../context/customsnackbar-context";
import { RealTokensContext } from "../../../context/realTokens-context";
import Web3 from "web3";

import {
	Box,
	Slide,
	Dialog,
	Button,
	Typography,
	useMediaQuery,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Avatar,
} from "@mui/material";
import { green, red, blue, purple, orange } from "@mui/material/colors";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedNumberDigit from "../../Common/Formater/FormatedNumberDigit";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function BuyYamOffer({ open, setOpen, offer }) {
	const { blockchainsClient, balanceWallets, settingsWallet } = useContext(SettingsContext);
	const { set_MarketSwapCatOfferUpdate,set_MarketYamOfferUpdate } = useContext(RealTokensContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { themeContext } = useContext(MyThemeContext);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleClose = () => {
		setOpen(false);
	};

	// let liveOffer = {};

	// console.log("offer", offer);
	// console.log("balanceWallets", balanceWallets[settingsWallet.selectedWallet].coins[settingsWallet.selectedBlockchain][offer.token_to_pay_name],settingsWallet);

	let weeks_delay = 0;
	if (offer.token_value_usd - offer.tokenPrice > 0) {
		weeks_delay = (offer.token_value_usd - offer.tokenPrice) / ((offer.tokenPrice * offer.annualPercentageYield) / 100 / 52);
		// console.log("weeks_delay", weeks_delay, "week(s)");
	}

	const bc = blockchainsClient["Gnosis"];
	const web3 = new Web3(bc.rpc);
	const yamAddress = "0xC759AA7f9dd9720A1502c104DaE4F9852bb17C14".toLowerCase();
	const swapcatAddress = "0xB18713Ac02Fc2090c0447e539524a5c76f327a3b".toLowerCase();
	const yamABI = [
		{ inputs: [], stateMutability: "nonpayable", type: "constructor" },
		{
			anonymous: false,
			inputs: [
				{ indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
				{ indexed: false, internalType: "address", name: "newAdmin", type: "address" },
			],
			name: "AdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "beacon", type: "address" }],
			name: "BeaconUpgraded",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "oldFee", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newFee", type: "uint256" },
			],
			name: "FeeChanged",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: true, internalType: "address", name: "seller", type: "address" },
				{ indexed: true, internalType: "address", name: "buyer", type: "address" },
				{ indexed: false, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferAccepted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address", name: "offerToken", type: "address" },
				{ indexed: true, internalType: "address", name: "buyerToken", type: "address" },
				{ indexed: false, internalType: "address", name: "seller", type: "address" },
				{ indexed: false, internalType: "address", name: "buyer", type: "address" },
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "price", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "OfferCreated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" }], name: "OfferDeleted", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "uint256", name: "offerId", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldPrice", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newPrice", type: "uint256" },
				{ indexed: false, internalType: "uint256", name: "oldAmount", type: "uint256" },
				{ indexed: true, internalType: "uint256", name: "newAmount", type: "uint256" },
			],
			name: "OfferUpdated",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" },
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "previousAdminRole", type: "bytes32" },
				{ indexed: true, internalType: "bytes32", name: "newAdminRole", type: "bytes32" },
			],
			name: "RoleAdminChanged",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleGranted",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
				{ indexed: true, internalType: "address", name: "account", type: "address" },
				{ indexed: true, internalType: "address", name: "sender", type: "address" },
			],
			name: "RoleRevoked",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, internalType: "address[]", name: "tokens", type: "address[]" },
				{ indexed: true, internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types", type: "uint8[]" },
			],
			name: "TokenWhitelistWithTypeToggled",
			type: "event",
		},
		{ anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" },
		{
			anonymous: false,
			inputs: [{ indexed: true, internalType: "address", name: "implementation", type: "address" }],
			name: "Upgraded",
			type: "event",
		},
		{
			inputs: [],
			name: "DEFAULT_ADMIN_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "MODERATOR_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "UPGRADER_ROLE",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "buy",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "buyOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "buyWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "createOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "_offerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyerTokens", type: "address[]" },
				{ internalType: "address[]", name: "_buyers", type: "address[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "createOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "offerToken", type: "address" },
				{ internalType: "address", name: "buyerToken", type: "address" },
				{ internalType: "address", name: "buyer", type: "address" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "createOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "deleteOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256[]", name: "offerIds", type: "uint256[]" }],
			name: "deleteOfferByAdmin",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "fee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" },
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "getInitialOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "getOfferCount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
			name: "getRoleAdmin",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "getTokenType",
			outputs: [{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType", name: "", type: "uint8" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "grantRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "hasRole",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "admin_", type: "address" },
				{ internalType: "address", name: "moderator_", type: "address" },
			],
			name: "initialize",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{ inputs: [], name: "pause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{ inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "pricePreview",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "proxiableUUID",
			outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "renounceRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "bytes32", name: "role", type: "bytes32" },
				{ internalType: "address", name: "account", type: "address" },
			],
			name: "revokeRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "token", type: "address" }],
			name: "saveLostTokens",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "fee_", type: "uint256" }],
			name: "setFee",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "offerId", type: "uint256" }],
			name: "showOffer",
			outputs: [
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "address", name: "", type: "address" },
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "uint256", name: "", type: "uint256" },
			],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
			name: "supportsInterface",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address[]", name: "tokens_", type: "address[]" },
				{ internalType: "enum IRealTokenYamUpgradeableV3.TokenType[]", name: "types_", type: "uint8[]" },
			],
			name: "toggleWhitelistWithType",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "tokenAddr", type: "address" }],
			name: "tokenInfo",
			outputs: [
				{ internalType: "uint256", name: "", type: "uint256" },
				{ internalType: "string", name: "", type: "string" },
				{ internalType: "string", name: "", type: "string" },
			],
			stateMutability: "view",
			type: "function",
		},
		{ inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" },
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "updateOffer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256[]", name: "_offerIds", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_prices", type: "uint256[]" },
				{ internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
			],
			name: "updateOfferBatch",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "uint256", name: "offerId", type: "uint256" },
				{ internalType: "uint256", name: "price", type: "uint256" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
				{ internalType: "uint256", name: "newAllowance", type: "uint256" },
				{ internalType: "uint256", name: "deadline", type: "uint256" },
				{ internalType: "uint8", name: "v", type: "uint8" },
				{ internalType: "bytes32", name: "r", type: "bytes32" },
				{ internalType: "bytes32", name: "s", type: "bytes32" },
			],
			name: "updateOfferWithPermit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "newImplementation", type: "address" }],
			name: "upgradeTo",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "newImplementation", type: "address" },
				{ internalType: "bytes", name: "data", type: "bytes" },
			],
			name: "upgradeToAndCall",
			outputs: [],
			stateMutability: "payable",
			type: "function",
		},
	];
	const swapcatABI = [
		{
			constant: true,
			inputs: [{ name: "_tokenaddr", type: "address" }],
			name: "tokeninfo",
			outputs: [
				{ name: "", type: "uint256" },
				{ name: "", type: "string" },
				{ name: "", type: "string" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "token", type: "address" }],
			name: "losttokens",
			outputs: [],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "deleteoffer",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offertoken", type: "address" },
				{ name: "_buyertoken", type: "address" },
				{ name: "_price", type: "uint256" },
				{ name: "_offerid", type: "uint24" },
			],
			name: "makeoffer",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [{ name: "_offerid", type: "uint24" }],
			name: "showoffer",
			outputs: [
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "address" },
				{ name: "", type: "uint256" },
				{ name: "", type: "uint256" },
			],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: true,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_amount", type: "uint256" },
			],
			name: "pricepreview",
			outputs: [{ name: "", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{
			constant: false,
			inputs: [
				{ name: "_offerid", type: "uint24" },
				{ name: "_offertokenamount", type: "uint256" },
				{ name: "_price", type: "uint256" },
			],
			name: "buy",
			outputs: [{ name: "", type: "string" }],
			payable: false,
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			constant: true,
			inputs: [],
			name: "getoffercount",
			outputs: [{ name: "", type: "uint24" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		{ payable: true, stateMutability: "payable", type: "fallback" },
	];
	const ERC20ABI = [
		// Allowance function
		{
			constant: true,
			inputs: [
				{ name: "_owner", type: "address" },
				{ name: "_spender", type: "address" },
			],
			name: "allowance",
			outputs: [{ name: "remaining", type: "uint256" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
		// Decimals function
		{
			constant: true,
			inputs: [],
			name: "decimals",
			outputs: [{ name: "", type: "uint8" }],
			payable: false,
			stateMutability: "view",
			type: "function",
		},
	];
	const yamContract = new web3.eth.Contract(yamABI, yamAddress);
	const swapcatContract = new web3.eth.Contract(swapcatABI, swapcatAddress);

	const getYamOffer = async () => {
		const yamOffer = await yamContract.methods.showOffer(offer.id_offer).call();
		const digitToken0 = await yamContract.methods.tokenInfo(yamOffer["0"]).call();
		const digitToken1 = await yamContract.methods.tokenInfo(yamOffer["1"]).call();
		// console.log("offerYam:", yamOffer);
		const priceOffer = Number(yamOffer["4"]) / 10 ** Number(digitToken1["0"]);
		const amountOffer = Number(yamOffer["5"]) / 10 ** Number(digitToken0["0"]);
		// console.log("amountOffer:", amountOffer);
		return { price: priceOffer, amount: amountOffer, coinAddress: yamOffer["1"].toLowerCase(), spender: yamAddress };
	};
	const getSwapcatOffer = async () => {
		const swapcatOffer = await swapcatContract.methods.showoffer(offer.id_offer).call();
		const digitToken0 = await swapcatContract.methods.tokeninfo(swapcatOffer["0"]).call();
		const digitToken1 = await swapcatContract.methods.tokeninfo(swapcatOffer["1"]).call();
		// console.log("swapcatOffer:", swapcatOffer);
		const priceOffer = Number(swapcatOffer["3"]) / 10 ** Number(digitToken1["0"]);
		const amountOffer = Number(swapcatOffer["4"]) / 10 ** Number(digitToken0["0"]);
		// console.log("amountOffer:", amountOffer);
		return { price: priceOffer, amount: amountOffer, coinAddress: swapcatOffer["1"].toLowerCase(), spender: swapcatAddress };
	};

	const getAllowanceCoin = async (coinAddress, spender) => {
		const coinContract = new web3.eth.Contract(ERC20ABI, coinAddress);
		const allowance = Number(await coinContract.methods.allowance(settingsWallet.selectedWallet.toLowerCase(), spender).call());
		const digit = Number(await coinContract.methods.decimals().call());
		// console.log("allowance", digit,allowance, Number(allowance)/10**digit, settingsWallet.selectedWallet, spender);
		return allowance / 10 ** digit;
	};

	const [liveOffer, setLiveOffer] = useState(null);
	const [allowanceCoin, setAllowanceCoin] = useState(null);

	useEffect(() => {
		const fetchOffer = async () => {
			if (open) {
				let offerData;
				let allowance;
				if (offer.sc === "yam") {
					offerData = await getYamOffer();
				}
				if (offer.sc === "swapcat") {
					offerData = await getSwapcatOffer();
				}
				setLiveOffer(offerData);
				allowance = await getAllowanceCoin(offerData.coinAddress, offerData.spender);
				setAllowanceCoin(allowance);
			}
		};

		fetchOffer();
	}, [open]);

	useEffect(() => {
		if (open && liveOffer) {
			console.log("liveOffer:", liveOffer);
			console.log("liveOffer.amount:", liveOffer.amount);
			console.log("offer.token_amount:", offer.token_amount);
			if (liveOffer.amount !== offer.token_amount) {
				openSnackbar("The offer is out of sync. An update request has been sent to the API", "error");
				if (offer.sc === "yam") set_MarketYamOfferUpdate(offer.id_offer);
				if (offer.sc === "swapcat") set_MarketSwapCatOfferUpdate(offer.id_offer);
			} else {
				openSnackbar("The offer is successfully synchronized", "success");
				// if (offer.sc === "yam") set_MarketYamOfferUpdate(offer.id_offer);
				// if (offer.sc === "swapcat") set_MarketSwapCatOfferUpdate(offer.id_offer);
			}
		}
	}, [open, liveOffer]);

	useEffect(() => {
		if (open && liveOffer) console.log("allowanceCoin:", allowanceCoin);
	}, [open, allowanceCoin]);

	if (!open) return <></>;
	if (!offer) return <></>;
	// if (offer.sc !== "yam") return <></>;

	const size_row_height = 30;
	const size_token_to_paid = 20;
	const size_col1 = 125;
	const size_col2 = 160;
	const coin_in_wallet = balanceWallets[settingsWallet.selectedWallet].coins[settingsWallet.selectedBlockchain][offer.token_to_pay_name].balance;

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby='alert-dialog-slide-description'
			sx={{
				m: 0,
				width: 1,
				"& .MuiDialog-paper": {
					width: isMatch ? 710 : 710,
					maxWidth: "none",
					border: 2,
					color: blue[600],
					borderRadius: 0,
				},
			}}
		>
			<Box sx={{ m: 0, border: 5, width: 1, color: blue[600], borderRadius: 0 }}>
				<DialogTitle sx={{ m: 0, pt: 2, pb: 1.5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
					<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
						<Box sx={{ ml: 0, width: 290, border: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>{"Buy a RealToken"}</Box>
						<Box sx={{ ml: 0, width: 290, border: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Box
								component='img'
								alt='SmartContract'
								src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"}
								sx={{ mx: 1, width: 24, height: 24 }}
							/>
							{offer.sc === "yam" ? "YAM offer n°" : "Swapcat offer n°"}
							{offer.id_offer}
						</Box>
					</Box>
				</DialogTitle>

				<Box
					sx={{
						px: 1,
						borderTop: 2,
						borderBottom: 2,
						color: blue[600],
						display: "flex",
						flexDirection: "row",
						alignItems: "top",
						justifyContent: "top",
					}}
				>
					<Box component='img' alt='imgToken' src={offer.token.imageLink[0]} sx={{ m: 0, width: 105, height: 105 }} />
					<Box sx={{ ml: 1, pt: 1 }}>
						<Box sx={{ pb: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", cursor: "pointer" }}>
							<Box
								sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
								onClick={() => window.open(offer.token.marketplaceLink, "_blank")}
							>
								<OpenInNewIcon />
							</Box>
							<Typography variant='body1' sx={{ ml: 1, color: blue[600] }} onClick={() => window.open(offer.token.marketplaceLink, "_blank")}>
								{offer.fullName}
							</Typography>
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90, display: "flex" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Price"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPrice variant='body2' value={offer.tokenPrice} color={blue[600]} />
							</Box>
							{offer.tokenPrice !== offer.token.historic.init_price && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography variant='body2' sx={{ color: orange[600] }}>
											{"Init Price"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPrice variant='body2' value={offer.token.historic.init_price} color={orange[600]} />
									</Box>
								</>
							)}
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90, display: "flex" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Yield"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPercentage variant='body2' value={offer.annualPercentageYield / 100} color={blue[600]} />
							</Box>
							{offer.annualPercentageYield !== offer.token.historic.init_yield && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography variant='body2' sx={{ color: orange[600] }}>
											{"Init Yield"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPercentage variant='body2' value={offer.token.historic.init_yield / 100} color={orange[600]} />
									</Box>
								</>
							)}
						</Box>

						<Box sx={{ ml: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
							<Box sx={{ ml: 0, width: 90 }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Rented"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: 60 }}>
								<FormatedPercentage variant='body2' value={offer.token_rented} color={offer.token_rented < 1 ? red[700] : green[600]} />
							</Box>
							{true && (
								<>
									<Box sx={{ ml: 2, width: 90 }}>
										<Typography
											variant='body2'
											sx={{ color: offer.token.historic.init_yield <= offer.token.historic.avg_yield ? green[700] : red[700] }}
										>
											{"Avg Yield"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: 60 }}>
										<FormatedPercentage
											variant='body2'
											value={offer.token.historic.avg_yield / 100}
											color={offer.token.historic.init_yield <= offer.token.historic.avg_yield ? green[700] : red[700]}
										/>
									</Box>
								</>
							)}
						</Box>
					</Box>
					<Box sx={{ ml: "auto", width: 50, border: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						<Avatar sx={{ bgcolor: offer.whiteList ? green[500] : red[700], width: 35, height: 35 }}>WL</Avatar>
					</Box>
				</Box>

				<DialogContent sx={{ m: 0, p: 0 }}>
					<>
						<Box
							sx={{ px: 0, pt: 2, border: 0, color: blue[600], display: "flex", flexDirection: "column", alignItems: "top", justifyContent: "top" }}
						>
							<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
								<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
									<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Price"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedNumberDigit variant='body2' value={offer.token_value} color={blue[600]} digit={2} />
										<Box
											component='img'
											alt='imgToken'
											src={offer.token_to_pay_img}
											sx={{
												ml: 0.5,
												my: 0,
												p: 0,
												width: size_token_to_paid,
												height: size_token_to_paid,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										/>
										<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
											{offer.token_to_pay_name.length > 7 ? offer.token_to_pay_name.slice(0, 7) + "..." : offer.token_to_pay_name}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"In your Wallet"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedNumberDigit variant='body2' value={coin_in_wallet} color={blue[600]} digit={2} />
										<Box
											component='img'
											alt='imgToken'
											src={offer.token_to_pay_img}
											sx={{
												ml: 0.5,
												my: 0,
												p: 0,
												width: size_token_to_paid,
												height: size_token_to_paid,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										/>
										<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
											{offer.token_to_pay_name.length > 7 ? offer.token_to_pay_name.slice(0, 7) + "..." : offer.token_to_pay_name}
										</Typography>
									</Box>
								</Box>
							</Box>

							<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
								<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
									<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Price in USD"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedPrice variant='body2' value={offer.token_value_usd} color={blue[600]} />
									</Box>
									<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Autorize to spend"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										<FormatedNumberDigit variant='body2' value={allowanceCoin} color={blue[600]} digit={2} />
										<Box
											component='img'
											alt='imgToken'
											src={offer.token_to_pay_img}
											sx={{
												ml: 0.5,
												my: 0,
												p: 0,
												width: size_token_to_paid,
												height: size_token_to_paid,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										/>
										<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
											{offer.token_to_pay_name.length > 7 ? offer.token_to_pay_name.slice(0, 7) + "..." : offer.token_to_pay_name}
										</Typography>
									</Box>
								</Box>
							</Box>

							<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
								<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
									<Box sx={{ ml: 0, width: size_col1, display: "flex" }}>
										<Typography variant='body2' sx={{ color: blue[600] }}>
											{"Offer Amount"}
										</Typography>
									</Box>
									<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
										{liveOffer && (
											<>
												{liveOffer.amount === 0 || offer.token_amount === 0 ? (
													<Typography variant='body2' sx={{ ml: 0.5, color: red[600] }}>
														{"Nothing to Buy"}
													</Typography>
												) : (
													<>
														{liveOffer.amount !== offer.token_amount ? (
															<FormatedNumberDigit variant='body2' value={liveOffer.amount} color={red[600]}  digit={4}/>
														) : (
															<FormatedNumberDigit variant='body2' value={offer.token_amount} color={blue[600]} digit={4} />
														)}
														<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
															{"realToken(s)"}
														</Typography>
													</>
												)}
											</>
										)}
									</Box>
								</Box>
							</Box>
						</Box>

						<Box sx={{ ml: 3, pt: 0, height: size_row_height }}>
							<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
								<Box sx={{ ml: 0, width: 2 * size_col1 + size_col2, display: "flex" }}>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{"Time to recoup the investment on this RealToken purchase:"}
									</Typography>
								</Box>
								<Box sx={{ ml: 0, width: size_col2, display: "flex", alignItems: "center", justifyContent: "start" }}>
									<FormatedNumber variant='body2' fontWeight='bold' value={weeks_delay} color={blue[600]} digit={1} />
									<Typography variant='body2' sx={{ ml: 0.5, color: blue[600] }}>
										{"week(s)"}
									</Typography>
								</Box>
							</Box>
						</Box>

						{false && (
							<DialogContentText id='alert-dialog-slide-description' sx={{ mt: 1, p: 1, border: 1, color: green[500] }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{offer.fullName}
								</Typography>
							</DialogContentText>
						)}
					</>
				</DialogContent>

				<DialogActions sx={{ py: 0, border: 0, color: red[500] }}>
					{/* <Button onClick={handleClose}>Disagree</Button> */}
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default BuyYamOffer;

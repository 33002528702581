import React, { useState, useEffect, useContext } from "react";
// import { MyThemeContext } from "../../context/theme-context";
import { SettingsContext } from "../../context/settings-context";
// import { SnackbarContext } from "../../context/customsnackbar-context";
import { Link } from "react-router-dom";

import { Box, Typography, Menu, MenuItem, ListItemIcon, Tooltip, Avatar, Divider, TextField } from "@mui/material";
import { Checkbox, TableRow, TableCell, IconButton, TableContainer, CircularProgress, ListItemButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Settings from "@mui/icons-material/Settings";

import WalletsMenuRow from "./WalletsMenuRow";

function WalletsMenu(props) {
	const { blockchainsClient, settingsWallet, setSettingsWallet, holderWallets } = useContext(SettingsContext);
	// const { openSnackbar } = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		props.menuClose();
	};

	useEffect(() => {
		if (props.open) {
			setOpen(true);
		}
	}, [props]);

	const selectedBlockchainChange = (newValue) => {
		// console.log("selectedBlockchainChange", newValue);
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			selectedBlockchain: newValue,
		}));
	};
	const selectedWalletChange = (newValue) => {
		// console.log("selectedWalletChange", newValue);
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			selectedWallet: newValue,
		}));
	};
	const selectedWalletHideChange = (newValue) => {
		// console.log("selectedWalletHideChange", newValue);
		// console.log("newValue", newValue, settingsWallet);
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			selectedWalletHideCoins: newValue,
		}));
	};

	if (!blockchainsClient) return <></>;
	if (!settingsWallet) return <></>;
	const coins = blockchainsClient[settingsWallet.selectedBlockchain].coinList;
	// console.log("coins", coins["xDAI"]);

	if (!coins) return <></>;
	return (
		<Box>
			<Menu
				id='account-menu'
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "top" }}
				sx={{ marginTop: 5, p: 5, width: "200%" }}
			>
				<TextField
					sx={{ m: 0, mt: 0.5 }}
					select
					size='small'
					id='selectedBlockchain'
					value={settingsWallet.selectedBlockchain}
					label='Set Blockchain'
					variant='outlined'
					fullWidth
					onChange={(e) => {
						// console.log("onChange", e.target.value);
						selectedBlockchainChange(e.target.value);
					}}
				>
					{Object.keys(blockchainsClient).map((key) => (
						<MenuItem value={key} key={key}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<ListItemIcon>
									<Avatar alt={blockchainsClient[key].name} src={blockchainsClient[key].image} fontSize='small' sx={{ width: 20, height: 20 }} />
								</ListItemIcon>
								<Typography sx={{ ml: 1 }} variant='body2'>
									{blockchainsClient[key].name}
								</Typography>
							</Box>
						</MenuItem>
					))}
				</TextField>
				{holderWallets && (
					<TextField
						sx={{ mt: 1 }}
						select
						size='small'
						id='selectedWallet'
						value={settingsWallet.selectedWallet ? settingsWallet.selectedWallet : ""}
						label='Set defiWallet'
						variant='outlined'
						fullWidth
						onChange={(e) => {
							console.log("onChange", e.target.value);
							selectedWalletChange(e.target.value);
						}}
					>
						<MenuItem value={""}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Typography variant='body2'>No wallet Selected</Typography>
							</Box>
						</MenuItem>
						{holderWallets &&
							holderWallets.map((w) => (
								<MenuItem key={w.address} value={w.address}>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2'>{w.address.slice(0, 6) + "..." + w.address.slice(-4)}</Typography>
									</Box>
								</MenuItem>
							))}
					</TextField>
				)}
				<MenuItem sx={{ pt: 1, pl: 1, pb: 0 }}>
					<ListItemIcon sx={{ m: 0, p: 0 }}>
						<Checkbox
							fontSize='small'
							// defaultChecked={false}
							checked={settingsWallet.selectedWalletHideCoins}
							sx={{ p: 0, pl: 1, ml: 0, mr: "auto", "& .MuiSvgIcon-root": { fontSize: 20 } }}
							onClick={() => selectedWalletHideChange(!settingsWallet.selectedWalletHideCoins)}
						/>
					</ListItemIcon>
					<Typography variant='body2' onClick={() => selectedWalletHideChange(!settingsWallet.selectedWalletHideCoins)}>
						{settingsWallet.selectedWalletHideCoins ? "Empty balances hide" : "Hide empty balances"}
					</Typography>
				</MenuItem>
				<Divider key={"div01"} sx={{ m: 0, mx: 1, p: 0 }} />
				<TableContainer sx={{ p: 0 }}>
					{coins &&
						Object.values(coins)
							.filter((coin) => (settingsWallet.selectedWalletHideCoins ? coin.show > 0 : coin.show >= 0))
							.map((coin,index) => <WalletsMenuRow key={index} coin={coin}  />)}
				</TableContainer>
				<Divider key={"div02"} />
				<MenuItem sx={{ pt: 1, pl: 1, pb: 0 }} onClick={handleClose}>
					<ListItemButton sx={{ m: 0, p: 0, ml: 1 }} component={Link} to={"/settings?tab=2"}>
						<ListItemIcon>
							<Settings fontSize='small' />
						</ListItemIcon>
						<Typography variant='body2'>Wallets Settings</Typography>
					</ListItemButton>
				</MenuItem>
			</Menu>
		</Box>
	);
}

export default WalletsMenu;

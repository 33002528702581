import React, { useContext, useState } from "react";
// import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";

// import { useTheme } from "@mui/system";
import { TableRow, TableCell, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { green, red } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
import FormatedNumber from "../../Common/Formater/FormatedNumber";
import TokenInfos from "../../Common/TokenInfos";

function TableLevinswapRow({ token }) {
	const { themeContext } = useContext(MyThemeContext);
	const [tokenAdded, setTokenAdded] = useState(0.0);
	const [openInfo, setOpenInfo] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleTokenClick = (Link) => {
		window.open("https://gnosisscan.io/token/" + Link + "#balances", "_blank");
		// https://gnosisscan.io/token/
	};

	const handleTokenRealTClick = (marketplaceLink) => {
		window.open(marketplaceLink, "_blank");
	};

	token.holderRentAdded = token.holderRent + (tokenAdded * token.tokenPrice * token.annualPercentageYield) / 100;

	token.poolYieldAdded =
		((token.realtTokens + tokenAdded + token.holderTokens) / (2 * (token.holderTokens + tokenAdded))) * token.annualPercentageYield;
	
	// if (token.nbToken) console.log(new Date(token.rent_start) > new Date(), token);

	return (
		<>
			<TokenInfos open={openInfo} setOpen={setOpenInfo} token={token} />
			<TableRow key={token.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
				<TableCell
					align='center'
					sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
					component='th'
					scope='row'
					onClick={() => handleTokenClick(token.secondaryMarketplaces[0].contractPool)}
				>
					<OpenInNewIcon />
				</TableCell>
				<TableCell
					align='left'
					sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
					// onClick={() => handleTokenRealTClick(token.marketplaceLink)}
					onClick={() => setOpenInfo(true)}
				>
					<Tooltip title={token.fullName}>
						<Typography variant='body2'>{token.shortName}</Typography>
					</Tooltip>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedNumber
						value={token.nbToken ? token.nbToken.nbGnosis - tokenAdded : 0 + tokenAdded}
						digit={6}
						color={token.nbToken ? (tokenAdded > 0 ? green[500] : red[700]) : tokenAdded > 0 ? green[500] : "secondary.main"}
					/>
				</TableCell>
				{!isMatch && (
					<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
						<Typography variant='body2' color={new Date(token.strInitialLaunchDate) > new Date() ? red[700] : green[500]}>
							{token.strInitialLaunchDate}
						</Typography>
					</TableCell>
				)}
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2' color={new Date(token.rent_start) > new Date() ? red[700] : green[500]}>
						{token.strRentStartDate}
					</Typography>
				</TableCell>
				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedNumber value={token.realtTokens} digit={6} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.realtRent} color={"primary.main"} />
				</TableCell>
				<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedNumber value={token.holderTokens} digit={6} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.holderRentAdded} color={"primary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.annualPercentageYield / 100} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.poolYield / 100} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedNumber
						value={token.nbToken ? token.nbToken.nb + tokenAdded : 0 + tokenAdded}
						digit={6}
						color={token.nbToken ? (tokenAdded > 0 ? green[500] : red[700]) : tokenAdded > 0 ? green[500] : "secondary.main"}
					/>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<TextField
						sx={{ mt: 0, ml: 0, minWidth: 5 }}
						// label='Yield Step (%)'
						onChange={(e) => {
							const value = parseFloat(e.target.value);
							setTokenAdded(isNaN(value) ? "" : value);
						}}
						variant='outlined'
						type='number'
						// type='text' // Change type to "text"
						size='small'
						value={tokenAdded === "" ? "" : tokenAdded} // Ensure value is set to '' if it's not a number
						inputProps={{
							min: 0.0,
							max: 10,
							step: 0.01,
							style: {
								textAlign: "center",
								padding: "5px 6px",
							},
						}}
					/>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.poolYieldAdded / 100} color={"secondary.main"} />
				</TableCell>
			</TableRow>
		</>
	);
}

export default TableLevinswapRow;

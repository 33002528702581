// src/context/web3-context.jsx
import React, { createContext, useState, useEffect, useContext } from "react";
import Web3 from "web3";

import { SettingsContext } from "./settings-context";

export const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
	const { blockchainsClient, settingsWallet } = useContext(SettingsContext);
	const [web3, setWeb3] = useState(null);
	const [account, setAccount] = useState(null);
	const [active, setActive] = useState(false);

	const connect = async () => {
		if (window.ethereum) {
			try {
				const web3Instance = new Web3(window.ethereum);
				await window.ethereum.enable(); // Request account access
				const accounts = await web3Instance.eth.getAccounts();
				setWeb3(web3Instance);
				setAccount(accounts[0]);
				console.log("User connected :", accounts[0]);
				switchNetwork();
				setActive(true);
				console.log("Web3", web3Instance, window.ethereum, accounts);
			} catch (error) {
				console.error("User denied account access...");
				setActive(false);
			}
		} else if (window.web3) {
			const web3Instance = new Web3(window.web3.currentProvider);
			const accounts = await web3Instance.eth.getAccounts();
			setWeb3(web3Instance);
			setAccount(accounts[0]);
			console.log("User connected :", accounts[0]);
			switchNetwork();
			setActive(true);
			console.log("Web3", web3Instance, window.ethereum, accounts);
		} else {
			console.log("Non-Ethereum browser detected. You should consider trying MetaMask!");
			setActive(false);
		}
	};

	// blockchainsClient settingsWallet.selectedBlockchain

	const disconnect = () => {
		setWeb3(null);
		setAccount(null);
		setActive(false);
	};

	const changeAccount = async () => {
		// console.log("switchNetwork ...");
		if (window.ethereum) {
			if (settingsWallet && blockchainsClient) {
				if (settingsWallet.selectedWallet) {
					const web3account = settingsWallet.selectedWallet;
					try {
						const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
						setAccount(accounts[0]);
					} catch (error) {
						console.error("Erreur lors du changement de compte", error);
					}
				}
			}
		}
	};

	const intToHex = (int) => {
		return "0x" + int.toString(16).padStart(2, "0");
	};

	const switchNetwork = async () => {
		// console.log("switchNetwork ...");
		if (window.ethereum) {
			if (settingsWallet && blockchainsClient) {
				if (settingsWallet.selectedBlockchain) {
					try {
						const chainId = intToHex(blockchainsClient[settingsWallet.selectedBlockchain].chainId);
						await window.ethereum.request({
							method: "wallet_switchEthereumChain",
							params: [{ chainId }],
						});
					} catch (error) {
						if (error.code === 4902) {
							console.log("Le réseau n'est pas ajouté dans MetaMask.");
						} else {
							console.error("Erreur lors du changement de réseau", error);
						}
					}
				}
			}
		}
	};

	const getBalance = async () => {
		if (web3 && account) {
			try {
				const balance = await web3.eth.getBalance(account);
				return balance;
			} catch (error) {
				console.error("Erreur lors de l'obtention du solde", error);
				return null;
			}
		}
		return null;
	};

	const sendTransaction = async (to, value) => {
		if (web3 && account) {
			try {
				const tx = {
					from: account,
					to: to,
					value: web3.utils.toWei(value, "ether"),
				};
				const result = await web3.eth.sendTransaction(tx);
				return result;
			} catch (error) {
				console.error("Erreur lors de l'envoi de la transaction", error);
				return null;
			}
		}
		return null;
	};

	useEffect(() => {
		console.log("Web3Context.Provider", "execution ...");
		if (window.ethereum) {
			window.ethereum.on("accountsChanged", (accounts) => {
				setAccount(accounts[0]);
			});
		}

		// Automatically connect if accounts are already connected
		if (!web3 && window.ethereum) {
			// connect();
		}
	}, [web3]);

	return <Web3Context.Provider value={{ web3, account, connect, disconnect, active, getBalance, sendTransaction }}>{children}</Web3Context.Provider>;
};

import React, { useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { MyThemeContext } from "./context/theme-context";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";


import CssBaseline from "@mui/material/CssBaseline";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import Stats from "./pages/Stats";
import Settings from "./pages/Settings";
import Help from "./pages/Help";
import Market from "./pages/Market";
import Traffic from "./pages/Traffic";

const App = () => {
	const { themeContext } = useContext(MyThemeContext);

	return (
		<>
			<ThemeProvider theme={themeContext}>
					<CssBaseline />
					<BrowserRouter>
						{/* {showHeader && <Header title={message} />} */}
						<Routes>
							<Route path='/dashboard' element={<Dashboard />} />
							<Route path='/about' element={<About />} />
							<Route path='/help' element={<Help />} />
							<Route path='/stats' element={<Stats />} />
							<Route path='/settings' element={<Settings />} />
							<Route path='/market' element={<Market />} />
							<Route path='/traffic' element={<Traffic />} />
							<Route path='*' element={<Navigate to='/stats' />} />
						</Routes>
					</BrowserRouter>
			</ThemeProvider>
		</>
	);
};

export default App;

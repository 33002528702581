import React, { useContext, useMemo } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Box, Typography, Paper, Select, MenuItem } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

function PiePropertyType() {
	const { realTokens, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
	const selectedRentType = "Year";

	if (!settingsDashboard.isExpandedCharts) return <></>;
	if (!realTokens) return <></>;
	if (!holderWallets) return <></>;
	if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
	if (!DEFAULT_PROPERTY_TYPE) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];

	let tokensBalance = [{ label: "PiePropertyType", chain: "total", count: 0, rent: 0, total: 0, yield: 0, tokens: [] }];

	if (balanceWallets) {
		tokensBalance.forEach((tokenBalance) => {
			holderWallets
				.filter((holderWallet) => holderWallet.checked)
				.forEach((holderWallet) => {
					for (const wallet of Object.keys(balanceWallets)) {
						if (holderWallet.address === wallet) {
							for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
								if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
									if (chain === "eth") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
												if (realToken) {
													if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
													if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
													if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
													if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
													tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
													tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
													tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
													let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
													if (existingTokenIndex >= 0) {
														// console.log("Update eth", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, token.amount);
														tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
														tokenBalance.tokens[existingTokenIndex].chain.push({
															id: "eth",
															amount: parseFloat(token.amount),
															wallet: wallet,
														});
													} else {
														// If the token doesn't exist, push a new entry
														tokenBalance.tokens.push({
															id: realToken.id,
															token: realToken,
															amount: parseFloat(token.amount),
															chain: [{ id: "eth", amount: parseFloat(token.amount), wallet: wallet }],
														});
													}
												}
												// }
											});
									}
								}

								if (tokenBalance.chain === "xdai" || tokenBalance.chain === "total") {
									if (chain === "xdai") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
												if (realToken) {
													// if (Date.now() > getNextRonday(realToken)) {
													if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
													if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
													if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
													if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
													tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
													tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
													tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
													let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
													if (existingTokenIndex >= 0) {
														tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
														tokenBalance.tokens[existingTokenIndex].chain.push({
															id: "xdai",
															amount: parseFloat(token.amount),
															wallet: wallet,
														});
													} else {
														tokenBalance.tokens.push({
															id: realToken.id,
															token: realToken,
															amount: parseFloat(token.amount),
															chain: [{ id: "xdai", amount: parseFloat(token.amount), wallet: wallet }],
														});
													}
												}
												// }
											});
									}
								}

								if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
									if (chain === "rmm") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												if (token.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
													const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
													if (realToken) {
														// if (Date.now() > getNextRonday(realToken)) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															// console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "rmm",
																amount: parseFloat(rmmBalance),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(rmmBalance),
																chain: [{ id: "rmm", amount: parseFloat(rmmBalance), wallet: wallet }],
															});
														}
														// }
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
									if (chain === "rmmv3") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
												if (token.token.id !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
													const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
													if (realToken) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															// console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "rmm",
																amount: parseFloat(rmmBalance),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(rmmBalance),
																chain: [{ id: "rmm", amount: parseFloat(rmmBalance), wallet: wallet }],
															});
														}
													}
												}
											});
									}
								}

								if (tokenBalance.chain === "pool" || tokenBalance.chain === "total") {
									if (chain === "pool") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												// console.log("chain", tokenBalance.chain, "walletBalance:", wallet, "token", token);
												const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
												const totalSupply = parseFloat(token.pair.totalSupply);
												if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
													if (realToken) {
														const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
														const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
														const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
														const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
														// if (Date.now() > getNextRonday(realToken)) {
														if (selectedRentType === "Day")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
														if (selectedRentType === "Week")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															// console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "pool",
																amount: parseFloat(poolBalance),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(poolBalance),
																chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
															});
														}
														// }
													}
												}
												if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
													if (realToken) {
														const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
														const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
														const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
														const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
														// if (Date.now() > getNextRonday(realToken)) {
														if (selectedRentType === "Day")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
														if (selectedRentType === "Week")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year")
															tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
														tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
														let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
														if (existingTokenIndex >= 0) {
															// console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
															tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
															tokenBalance.tokens[existingTokenIndex].chain.push({
																id: "pool",
																amount: parseFloat(poolBalance),
																wallet: wallet,
															});
														} else {
															// If the token doesn't exist, push a new entry
															tokenBalance.tokens.push({
																id: realToken.id,
																token: realToken,
																amount: parseFloat(poolBalance),
																chain: [{ id: "pool", amount: parseFloat(poolBalance), wallet: wallet }],
															});
														}
														// }
													}
												}
											});
									}
								}
							}
						}
					}
				});
		});
	}

	if (!tokensBalance[0].tokens.length > 0) return <></>;
	const propertyTypes_nonOrdered = Array.from(new Set(tokensBalance[0].tokens.map((data) => parseInt(data.token.propertyType)))).sort(
		(a, b) => a - b
	);
	const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));

	let propertyCount = propertyTypes.map((type) => ({
		realtokens: tokensBalance[0].tokens.filter((data) => DEFAULT_PROPERTY_TYPE[data.token.propertyType] === type).length,
		capital: tokensBalance[0].tokens.reduce(
			(acc, data) => acc + (DEFAULT_PROPERTY_TYPE[data.token.propertyType] === type ? data.amount * data.token.tokenPrice : 0 || 0),
			0
		),
	}));

	const totalProperties = parseInt(tokensBalance[0].tokens.reduce((acc, data) => acc + (data.token.totalUnits > 0 ? 1 : 0 || 0), 0));
	const totalCapital = parseInt(
		tokensBalance[0].tokens.reduce((acc, data) => acc + (data.amount > 0 ? data.amount * data.token.tokenPrice : 0 || 0), 0)
	);

	let data = propertyCount.map((val, index) =>
		settingsDashboard.selectedCalculType === "RealTokens"
			? {
					realtokens: val.realtokens,
					value: parseFloat(((100 * val.realtokens) / totalProperties).toFixed(1)),
					label: `${propertyTypes[index].label} (${val.realtokens})`,
			  }
			: {
					realtokens: val.realtokens,
					value: parseFloat(((100 * val.capital) / totalCapital).toFixed(1)),
					label: `${propertyTypes[index].label} (${
						val.capital > 1000000 ? (val.capital / 1000000).toFixed(2) + "M$" : (val.capital / 1000).toFixed(2) + "k$"
					})`,
			  }
	);
	data = data.sort((a, b) => b.realtokens - a.realtokens);
	// console.log("data", tokensBalance[0]);

	const setSettingsDashboardChange = (key, newValue) => {
		// console.log("setSettingsStatsChange:", key, newValue);
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	const chartSetting = {
		width: settingsDashboard.selectedCalculType === "RealTokens" ? 475 : 510,
		height: 325,
	};

	// console.log("data:", data);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: "auto", border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Properties to
				</Typography>
				<Select
					sx={{ mr: 1, minWidth: 125 }}
					value={settingsDashboard.selectedCalculType}
					onChange={(e) => setSettingsDashboardChange("selectedCalculType", e.target.value)}
					size='small'
				>
					{settingsDashboard.calculType.map((p, index) => (
						<MenuItem key={index} value={p}>
							{p}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Ratio
				</Typography>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", m: 0 }}>
				<PieChart
					series={[
						{
							arcLabel: (item) => `${item.value}%`,
							arcLabelMinAngle: 15,
							data,
							innerRadius: 90,
							outerRadius: 140,
							paddingAngle: 0,
							cornerRadius: 5,
							startAngle: 0,
							endAngle: 360,
							cx: 155,
							cy: 160,
						},
					]}
					sx={{
						[`& .${pieArcLabelClasses.root}`]: {
							fill: "white",
							fontWeight: "bold",
							// minHeight: 350
						},
					}}
					slotProps={{
						legend: {
							labelStyle: {
								fontSize: 12,
								// display: 'flex',
								// flexDirection: 'column',
								// flexWrap: 'wrap',
								// height: '300px',  // adjust as needed
							},
						},
					}}
					{...chartSetting}
				/>
			</Box>
		</Paper>
	);
}

export default PiePropertyType;

import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Select, MenuItem, Box, Typography, Paper } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

function PiePropertyType() {
	const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	if (!DEFAULT_PROPERTY_TYPE) return <></>;
	if (realTokens.list.length === 0) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];

	const propertyTypes_nonOrdered = Array.from(new Set(tokens.map((data) => parseInt(data.propertyType)))).sort((a, b) => a - b);
	const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));
	let propertyCount = propertyTypes.map((type) => ({
		realtokens: tokens.filter((data) => DEFAULT_PROPERTY_TYPE[data.propertyType] === type).length,
		capital: tokens.reduce((acc, data) => acc + (DEFAULT_PROPERTY_TYPE[data.propertyType] === type ? data.totalInvestment : 0 || 0), 0),
	}));

	const totalProperties = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0 || 0), 0));
	const totalCapital = parseInt(tokens.reduce((acc, data) => acc + (data.totalInvestment > 0 ? data.totalInvestment : 0 || 0), 0));

	let data = propertyCount.map((val, index) =>
		settingsStats.selectedCalculType === "RealTokens"
			? {
					realtokens: val.realtokens,
					value: parseFloat(((100 * val.realtokens) / totalProperties).toFixed(1)),
					label: `${propertyTypes[index].label} (${val.realtokens})`,
			  }
			: {
					realtokens: val.realtokens,
					value: parseFloat(((100 * val.capital) / totalCapital).toFixed(1)),
					label: `${propertyTypes[index].label} (${
						val.capital > 1000000 ? (val.capital / 1000000).toFixed(2) + "M$" : (val.capital / 1000).toFixed(2) + "k$"
					})`,
			  }
	);
	data = data.sort((a, b) => b.realtokens - a.realtokens);

	const setSettingsStatsChange = (key, newValue) => {
		// console.log("setSettingsStatsChange:", key, newValue);
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	const chartSetting = {
		width: settingsStats.selectedCalculType === "RealTokens" ? 475 : 520,
		height: 325,
	};

	// console.log("data:", data);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: "auto", border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Properties to
				</Typography>
				<Select
					sx={{ mr: 1, minWidth: 125 }}
					value={settingsStats.selectedCalculType}
					onChange={(e) => setSettingsStatsChange("selectedCalculType", e.target.value)}
					size='small'
				>
					{settingsStats.calculType.map((p, index) => (
						<MenuItem key={index} value={p}>
							{p}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Ratio
				</Typography>
			</Box>
			<Box sx={{ border: 0, display: "flex", alignItems: "center", justifyContent: "center", p: 0 }}>
				<PieChart
					series={[
						{
							arcLabel: (item) => `${item.value}%`,
							arcLabelMinAngle: 15,
							data,
							innerRadius: 90,
							outerRadius: 140,
							paddingAngle: 0,
							cornerRadius: 5,
							startAngle: 0,
							endAngle: 360,
							cx: 155,
							cy: 160,
						},
					]}
					sx={{
						[`& .${pieArcLabelClasses.root}`]: {
							fill: "white",
							fontWeight: "bold",
							// minHeight: 350
						},
					}}
					slotProps={{
						legend: {
							labelStyle: {
								fontSize: 12,
							},
						},
					}}
					{...chartSetting}
				/>
			</Box>
		</Paper>
	);
}

export default PiePropertyType;

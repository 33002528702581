import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Box, Typography, Paper } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

function PieYearHolderCost() {
	const { realTokens } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];

	const propertyManagement = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyManagement || 0), 0));
	const propertyMaintenanceMonthly = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyMaintenanceMonthly || 0), 0));
	const realtPlatform = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.realtPlatform || 0), 0));
	// const miscellaneousCosts = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.miscellaneousCosts || 0), 0));
	const propertyTaxes = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyTaxes || 0), 0));
	const insurance = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.insurance || 0), 0));
	const utilities= 12 * parseInt(tokens.reduce((acc, data) => acc + (data.utilities || 0), 0));
	const total = (propertyManagement + propertyMaintenanceMonthly + realtPlatform + utilities + propertyTaxes + insurance) / 100;

	const data = [
		{ value: propertyTaxes / total, label: "Property Taxes" },
		{ value: propertyManagement / total, label: "Property Management" },
		{ value: propertyMaintenanceMonthly / total, label: "Property Maintenance" },
		{ value: utilities / total, label: "Utilities Cost" },
		{ value: insurance / total, label: "Insurances Cost" },
		{ value: realtPlatform / total, label: "RealT Rent Distribution" },
		// { value: miscellaneousCosts / total, label: "Miscellaneous Costs" },
	];
	// console.log("data", data);

	const chartSetting = {
		width: 530,
		height: 300,
	};

	// console.log("totalGrossRentYear", tokens[0]);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 300, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Holder Cost Ratio
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<PieChart
					series={[
						{
							arcLabel: (item) => `${item.value.toFixed(1)}%`,
							arcLabelMinAngle: 15,
							data,
							innerRadius: 90,
							outerRadius: 140,
							paddingAngle: 0,
							cornerRadius: 5,
							startAngle: 0,
							endAngle: 360,
							cx: 150,
							cy: 150,
						},
					]}
					sx={{
						[`& .${pieArcLabelClasses.root}`]: {
							fill: "white",
							fontWeight: "bold",
						},
					}}
					{...chartSetting}
				/>
			</Box>
		</Paper>
	);
}

export default PieYearHolderCost;

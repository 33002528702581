import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { List, ListItemText, Collapse, Typography, Box, ListItemButton, Avatar, IconButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function HorizontalCollapsibleTokenList() {
	const { realTokens } = useContext(SettingsContext);
	const [openMonth, setOpenMonth] = useState({});

	if (!realTokens) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];
	const now = new Date();

	// Logic to add isNew key
	tokens.forEach((token) => {
		if (!token.initialLaunchDate) {
			console.log("No initialLaunchDate for ", token.fullName);
			token.initialLaunchDate = token.rentStartDate;
		}
		const initialDate = new Date(token.initialLaunchDate.date);
		const sevenDaysAgo = new Date();
		sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 30);
		token.isNew = initialDate > sevenDaysAgo;
	});

	const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));

	const filteredTokens = tokens.filter((token) => {
		const launchDate = new Date(token.initialLaunchDate.date);
		return launchDate >= sixMonthsAgo;
	});

	const groupedByMonth = filteredTokens.reduce((acc, token) => {
		const monthYear = `${token.initialLaunchDate.date.slice(0, 7)}`; // "YYYY-MM"
		if (!acc[monthYear]) {
			acc[monthYear] = { count: 0, label: monthYear, tokens: [] };
		}
		acc[monthYear].count += 1;
		acc[monthYear].tokens.push(token);
		return acc;
	}, {});

	const listData = Object.values(groupedByMonth).sort((a, b) => a.label.localeCompare(b.label));

	const handleClick = (month) => {
		// console.log("month", month);
		setOpenMonth((prevState) => ({ [month]: !prevState[month] }));
	};

	const handleTokenClick = (marketplaceLink) => {
		window.open(marketplaceLink, "_blank");
	};

	// console.log("listData", listData);

	return (
		<Box sx={{ display: "flex", flexDirection: "row", overflowX: "auto" }}>
			<List component='nav' sx={{ display: "flex" }}>
				{listData.map((monthData) => (
					<React.Fragment key={monthData.label}>
						<ListItemButton
							onClick={() => handleClick(monthData.label)}
							sx={{
								flexDirection: "column",
								alignItems: "center",
								// bgcolor: monthData.label === openMonth[monthData.label] ? "primary.main" : "inherit",
								// color: monthData.label === openMonth[monthData.label] ? "primary.contrastText" : "inherit",
								"&:hover": {
									bgcolor: "primary.dark",
								},
							}}
						>
							<ListItemText primary={monthData.label} secondary={`${monthData.count} Properties`} />
							{openMonth[monthData.label] ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={openMonth[monthData.label]} timeout='auto' unmountOnExit sx={{ width: "100%" }}>
							<List component='div' disablePadding sx={{ display: "flex", flexDirection: "column" }}>
								{monthData.tokens.map((token, idx) => (
									<ListItemButton key={idx} sx={{ px: 2 }} onClick={() => handleTokenClick(token.marketplaceLink)}>
										<Typography variant='body2' sx={{ mx: 1, color: "primary.main" }}>
											{idx + 1}
										</Typography>
										<Typography variant='body2' sx={{ mx: 1, color: "primary.main" }}>
											{token.tokenPrice}$
										</Typography>
										<Typography variant='body2' sx={{ mx: 1, color: "primary.main" }}>
											{token.annualPercentageYield.toFixed(2)}%
										</Typography>
										<Typography variant='body2' sx={{ mx: 1, color: "primary.main" }}>
											{token.fullName}
										</Typography>
										{token.isNew && (
											<IconButton sx={{ ml: 1 }}>
												<Avatar
													alt='Logo'
													src='/images/new_gpt.png'
													sx={{ ml: 0, width: 25, height: 25, imgProps: { style: { filter: "brightness(0) invert(0)" } } }}
												/>
											</IconButton>
										)}
									</ListItemButton>
								))}
							</List>
						</Collapse>
					</React.Fragment>
				))}
			</List>
		</Box>
	);
}

export default HorizontalCollapsibleTokenList;

import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";

import { Box, TableCell, TableRow, Typography, useMediaQuery, Avatar, Tooltip } from "@mui/material";
import { green, red, deepOrange } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
import FormatedNumberDays from "../../Common/Formater/FormatedNumberDays";

function PurchasesOffersBody({ offer, index }) {
	const { settingsMarket } = useContext(SettingsContext);
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [timerId, setTimerId] = useState(null);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	if (!offer) return <></>;
	// console.log("PurchasesOffersBody", offer);

	const handleOfferClick = (offer) => {
		// offer.id_offer
		if (offer.sc === "yam") window.open("https://yam.realtoken.network/offer/" + offer.id_offer, "_blank");
		if (offer.sc === "swapcat")
			window.open(
				"https://ipfs.io/ipfs/QmZ8wkYtFgGXpHpPGGjQAynS5m8jc4df9KwD4v1pV27yBK/offer.htm?o=" +
					offer.id_offer +
					"&c=100&BUY." +
					offer.token.symbol +
					".tokens.on.xdai.blockchain.DEX.OTC",
				"_blank"
			);
	};

	return (
		<TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
			<TableCell
				align='center'
				sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
				component='th'
				scope='row'
				onClick={() => handleOfferClick(offer)}
			>
				<OpenInNewIcon />
				{offer.id_offer}
			</TableCell>
			<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
				{/* <Avatar alt='SmartContract' src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"} sx={{  width: 24, height: 24 , borderRadius: '0'}} /> */}
				<Box
					component='img'
					alt='SmartContract'
					src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"}
					sx={{ m: 0, width: 24, height: 24 }}
				/>
			</TableCell>
			<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
				<Tooltip title={offer.fullName}>
					<Typography variant='body2' sx={{ color: offer.whiteList ? green[500] : red[700] }}>
						{offer.shortName}
					</Typography>
				</Tooltip>
			</TableCell>
			<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
				<Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
					{offer.city}
				</Typography>
			</TableCell>
			{settingsMarket.PurchaseOffers.showPosition && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					{offer.position === 0 || offer.position >= 0.01 ? (
						<FormatedNumber value={offer.position} color={"primary.main"} />
					) : (
						<FormatedNumber value={offer.position} color={"primary.main"} digit={10} />
					)}
				</TableCell>
			)}
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={offer.tokenPrice} color={"primary.main"} />
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPercentage value={offer.annualPercentageYield / 100} color={"primary.main"} />
			</TableCell>
			<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
					<Tooltip title={offer.token_to_pay_name}>
						<Avatar alt='coin' src={offer.token_to_pay_img} sx={{ m: 0, width: 20, height: 20 }} />
					</Tooltip>
				</Box>
			</TableCell>
			{/* <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedNumber value={offer.token_value} color={"secondary.main"} />
			</TableCell> */}
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				{offer.token_amount === 0 || offer.token_amount >= 0.01 ? (
					<FormatedNumber value={offer.token_amount} color={"secondary.main"} />
				) : (
					<FormatedNumber value={offer.token_amount} color={"secondary.main"} digit={10} />
				)}
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={offer.token_value_usd} color={"secondary.main"} />
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPercentage value={offer.token_newYield} color={"primary.main"} />
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPercentage
					value={offer.token_ratio}
					color={offer.token_ratio < 1 ? (offer.token_ratio < 0.8 ? red[700] : deepOrange[200]) : green[500]}
				/>
			</TableCell>
			{settingsMarket.PurchaseOffers.showRented && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={offer.token_rented} color={offer.token_rented < 1 ? red[700] : green[500]} />
				</TableCell>
			)}
			{settingsMarket.PurchaseOffers.showLastPricingDays && (
				<><TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedNumberDays
						value={offer.last_pricing_days}
						color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
					/>
				</TableCell>
					<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
						<FormatedNumber
							value={offer.nb_pricing}
							// color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
						/>
					</TableCell></>
			)}
			{settingsMarket.PurchaseOffers.showRentStart && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2'>{offer.rentStartDate}</Typography>
				</TableCell>
			)}
		</TableRow>
	);
}

export default PurchasesOffersBody;

import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { SnackbarContext } from "../../../context/customsnackbar-context";
import { RealTokensContext } from "../../../context/realTokens-context";

import { Box, Checkbox, IconButton, TextField, Typography, fabClasses } from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function Holders() {
	const { balanceWallets, setBalanceWallets, historyWallets, setHistoryWallets, holderWallets, setHolderWallets } = useContext(SettingsContext);
	const { settingsWallet, setSettingsWallet } = useContext(SettingsContext);
	const { isUpdate_Wallets, isGetCoinsValuesToUsd, isGetCoinsBalance } = useContext(RealTokensContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const [holderWallet, setHolderWallet] = useState("");

	const selectedWalletChange = (newValue) => {
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			selectedWallet: newValue,
		}));
	};

	const handleHolderWallet = () => {
		if (!holderWallets) {
			addHolderWallet();
		} else {
			if (!holderWallets.find((w) => w.address === holderWallet)) {
				addHolderWallet();
			} else {
				openSnackbar("The selected holder wallet Address is in the List ...", "error");
			}
		}
	};
	const addHolderWallet = () => {
		if (holderWallet.substring(0, 2) === "0x") {
			if (!holderWallets) {
				openSnackbar("You add your 1st holder wallet address : " + holderWallet, "success");
				setHolderWallets([{ address: holderWallet.toLowerCase(), checked: true }]);
				if (!settingsWallet.selectedWallet) selectedWalletChange(holderWallet.toLowerCase());
				setHolderWallet("");
			} else {
				openSnackbar("You add the holder wallet address : " + holderWallet, "success");
				setHolderWallets((prevHolderWallets) => [...prevHolderWallets, { address: holderWallet.toLowerCase(), checked: true }]);
				setHolderWallet("");
			}
		} else {
			openSnackbar("The selected holder wallet Address must start with 0x...", "warning");
		}
	};

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	
	const toogleHolderWallet = (wallet) => {
		const filtered = holderWallets.filter((w) => w.address !== wallet.wallet.address);
		const selected = holderWallets.filter((w) => w.address === wallet.wallet.address);
		selected[0].checked = !selected[0].checked;
		if (filtered.length === 0) setHolderWallets(selected);
		else setHolderWallets(filtered.concat(selected));
	};

	const removeHolderWallet = async (address) => {
		const filtered = holderWallets.filter((w) => w.address !== address);
		if (filtered.length === 0) setHolderWallets(null);
		else setHolderWallets(filtered);
		openSnackbar("You remove the holder wallet address : " + address, "success");

		while (true) {
			if (!isUpdate_Wallets && !isGetCoinsValuesToUsd && !isGetCoinsBalance) {
				let newBalances = JSON.parse(JSON.stringify(balanceWallets)) || {};
				let newHistoBalances = JSON.parse(JSON.stringify(historyWallets)) || {};
				delete newBalances[address];
				delete newHistoBalances[address];
				setBalanceWallets(newBalances);
				setHistoryWallets(newHistoBalances);
				break;
			}
			await sleep(500);
			// console.log("Waiting Erase", isUpdate_Wallets, isGetCoinsValuesToUsd, isGetCoinsBalance);
		}
	};

	return (
		<Box
			sx={{
				m: 1,
				p: 1,
				border: 1,
				borderColor: "primary.main",
				borderRadius: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant='h4' sx={{ my: 1, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
				Holder Wallets
			</Typography>

			{/* <Button onClick={() => openSnackbar("Message de test", "warning")}>Ouvrir Snackbar</Button> */}

			{holderWallets &&
				holderWallets
					.sort((a, b) => a.address.localeCompare(b.address))
					.map((wallet) => (
						<Box
							key={wallet.address}
							sx={{
								mx: 1,
								p: 0,
								minWidth: 550,
								border: 0,
								borderColor: "primary.main",
								borderRadius: 4,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Checkbox checked={wallet.checked} sx={{ mr: 3 }} onClick={() => toogleHolderWallet({ wallet })} />
							<Typography variant='body2' sx={{ mr: "auto", display: "flex", justifyContent: "start", alignItems: "center" }}>
								{wallet.address}
							</Typography>
							<IconButton
								sx={{ width: 6, height: 6 }}
								variant='contained'
								onClick={() => {
									navigator.clipboard.writeText(wallet.address);
									openSnackbar("Copied : " + wallet.address, "success");
								}}
							>
								<ContentCopyIcon />
							</IconButton>
							<IconButton variant='contained' color='error' onClick={() => removeHolderWallet(wallet.address)}>
								<DeleteForever />
							</IconButton>
						</Box>
					))}

			<Box
				sx={{
					m: 1,
					p: 0,
					minWidth: 550,
					borderTop: 1,
					borderColor: "primary.main",
					borderRadius: 0,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<TextField
					sx={{ mt: 1, width: 1 }}
					label='Wallet address (start with 0x...)'
					onChange={(e) => setHolderWallet(e.target.value)}
					onFocus={(e) => e.target.select()}
					variant='outlined'
					type='text'
					size='small'
					value={holderWallet}
				/>
				<IconButton sx={{ ml: 1 }} variant='contained' color='success' onClick={handleHolderWallet}>
					<AddCircleIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

export default Holders;

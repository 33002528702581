import React from "react";
import { Typography } from "@mui/material";

const FormatedNumberDigit = ({ value, color = "inherit", variant = "body2", fontWeight = "normal", digit = 2 }) => {
	// const userLocale = navigator.language; // const userLocales = navigator.languages;
	// console.log("userLocale", userLocale); // console.log(userLocales[0]);

	let formatedNumber =
		value !== null
			? !isNaN(value)
				? (parseInt(10 ** digit * value) / 10 ** digit).toLocaleString(undefined, { minimumFractionDigits: digit, maximumFractionDigits: digit })
				: "NaN"
			: "N/A";
	

	return (
		<Typography variant={variant} sx={{ color: color }} style={{ fontWeight: fontWeight }}>
			{formatedNumber}
		</Typography>
	);
};

export default FormatedNumberDigit;

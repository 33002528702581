import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from "@mui/material";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
// import { useTheme } from "@mui/system";

function TableValuationAssets({ defaultPropertyType = 0 }) {
	const { realTokens } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];
	if (defaultPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === defaultPropertyType);

	const valuationTokens = tokens.filter((data) => data.historic.prices.length > 1);
	// console.log("token", tokens[0], tokens[0].historic.init_price, tokens[0].totalTokens);

	const renovationReserve = parseInt(tokens.reduce((acc, data) => acc + (data.renovationReserve || 0), 0));
	const totalInitPrice = parseInt(valuationTokens.reduce((acc, data) => acc + (data.historic.init_price * data.totalTokens || 0), 0));
	const totalActualPrice = parseInt(valuationTokens.reduce((acc, data) => acc + (data.tokenPrice * data.totalTokens || 0), 0));

	// let total1 = 0;
	// let total2 = 0;
	// valuationTokens.forEach((token) => {
	// 	total1 += token.historic.init_price * token.totalTokens;
	// 	total2 += token.tokenPrice * token.totalTokens;
	// 	const add = token.tokenPrice * token.totalTokens - token.historic.init_price * token.totalTokens;
	// 	console.log("tokenValuation", token.shortName, total1, total2, add);
	// });
	// console.log("totalGrossRentYear", totalGrossRentYear);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Assets Valuations
				</Typography>
			</Box>
			<Box>
				<TableContainer>
					<Table
						sx={{
							minWidth: 300,
							background: "background.paper",
							"& thead th": { borderWidth: 0 },
							"& tbody tr:last-child td": { borderBottom: 0 },
						}}
						aria-label='sticky table'
					>
						<TableBody sx={{ mt: 0 }}>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Acquisition Price
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={totalInitPrice} variant={"body1"} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Current Price
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={totalActualPrice} variant={"body1"} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span' style={{ fontWeight: "bold" }} sx={{ color: "primary.main" }}>
										Assets Performance
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPercentage
										value={(totalActualPrice - totalInitPrice) / totalInitPrice}
										variant={"body1"}
										color={"primary.main"}
										fontWeight={"bold"}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Renovations Reserve
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={renovationReserve} variant={"body1"} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	);
}

export default TableValuationAssets;

import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";

import { Box, TableCell, TableRow, Typography, useMediaQuery, Avatar, Tooltip, TextField } from "@mui/material";
import { green, red, deepOrange,blue } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";

import TokenAmountShort from "../../Settings/Common/TokenAmountShort";

const SIZE_ICON=30

function WalletPurchasesOffersBody({ offer, index }) {
	const { settingsMarket, settingsWallet, blockchainsClient } = useContext(SettingsContext);
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [timerId, setTimerId] = useState(null);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	// const tokenToPaid = Object.values(blockchainsClient[settingsWallet.selectedBlockchain].coinList).find(
	// 	(coin) => coin.address.toLowerCase() === offer.token_to_pay.toLowerCase()
	// );

	if (!offer) return <></>;
	// console.log("PurchasesOffersBody", offer);

	const handleOfferClick = (offer) => {
		// offer.id_offer
		if (offer.sc === "yam") window.open("https://yam.realtoken.network/offer/" + offer.id_offer, "_blank");
		if (offer.sc === "swapcat") window.open("https://swap.cat/?oid=" + offer.id_offer + "&chain=100", "_blank");
	};

	return (
		<TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
			<TableCell
				align='center'
				sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
				component='th'
				scope='row'
				onClick={() => handleOfferClick(offer)}
			>
				<OpenInNewIcon />
			</TableCell>

			<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
				{/* <Avatar alt='SmartContract' src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"} sx={{  width: 24, height: 24 , borderRadius: '0'}} /> */}
				<Box
					component='img'
					alt='SmartContract'
					src={offer.sc === "yam" ? "/images/yam.png" : "/images/swapcat.png"}
					sx={{ m: 0, width: 24, height: 24 }}
				/>
			</TableCell>

			<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
				<Tooltip title={offer.fullName}>
					<Typography variant='body2' sx={{ color: offer.whiteList ? green[500] : red[700] }}>
						{offer.shortName}
					</Typography>
				</Tooltip>
			</TableCell>

			{/* <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
						{offer.city}
					</Typography>
			</TableCell> */}

			{settingsMarket.PurchaseOffers.showPosition && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					{offer.position === 0 || offer.position >= 0.01 ? (
						<FormatedNumber value={offer.position} color={"primary.main"} />
					) : (
						<FormatedNumber value={offer.position} color={"primary.main"} digit={10} />
					)}
				</TableCell>
			)}

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={offer.tokenPrice} color={"primary.main"} />
			</TableCell>

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPercentage value={offer.annualPercentageYield / 100} color={"primary.main"} />
			</TableCell>

			{settingsMarket.PurchaseOffers.showRented && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={offer.rentedUnits / offer.totalUnits} color={offer.token_rented < 1 ? red[700] : green[500]} />
				</TableCell>
			)}

			{settingsMarket.PurchaseOffers.showRentStart && (
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2'>{offer.rent_start.slice(0, 10)}</Typography>
				</TableCell>
			)}

			<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
				<TokenAmountShort
					value={0}
					// onChange={(newValue) => {
					// 	let settings = JSON.parse(JSON.stringify(settingsMarket));
					// 	settings.PurchaseOffers.minPosition = newValue;
					// 	if (
					// 		settings.PurchaseOffers.maxPosition < settings.PurchaseOffers.minPosition ||
					// 		!settings.PurchaseOffers.maxPosition
					// 	)
					// 		settings.PurchaseOffers.maxPosition = settings.PurchaseOffers.minPosition + 0.1;
					// 	setSettingsMarket(settings);
					// }}
					min={0}
					max={1000}
					step={0.01}
					title='Offer Token'
				/>
			</TableCell>

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<TokenAmountShort
					value={0}
					// onChange={(newValue) => {
					// 	let settings = JSON.parse(JSON.stringify(settingsMarket));
					// 	settings.PurchaseOffers.minPosition = newValue;
					// 	if (
					// 		settings.PurchaseOffers.maxPosition < settings.PurchaseOffers.minPosition ||
					// 		!settings.PurchaseOffers.maxPosition
					// 	)
					// 		settings.PurchaseOffers.maxPosition = settings.PurchaseOffers.minPosition + 0.1;
					// 	setSettingsMarket(settings);
					// }}
					min={0}
					max={1000}
					step={0.01}
					title='Offer Amount'
				/>
			</TableCell>

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<TokenAmountShort
					value={0}
					// onChange={(newValue) => {
					// 	let settings = JSON.parse(JSON.stringify(settingsMarket));
					// 	settings.PurchaseOffers.minPosition = newValue;
					// 	if (
					// 		settings.PurchaseOffers.maxPosition < settings.PurchaseOffers.minPosition ||
					// 		!settings.PurchaseOffers.maxPosition
					// 	)
					// 		settings.PurchaseOffers.maxPosition = settings.PurchaseOffers.minPosition + 0.1;
					// 	setSettingsMarket(settings);
					// }}
					min={0}
					max={1000}
					step={0.01}
					title='Offer Price'
				/>
			</TableCell>

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<TokenAmountShort
					value={0}
					// onChange={(newValue) => {
					// 	let settings = JSON.parse(JSON.stringify(settingsMarket));
					// 	settings.PurchaseOffers.minPosition = newValue;
					// 	if (
					// 		settings.PurchaseOffers.maxPosition < settings.PurchaseOffers.minPosition ||
					// 		!settings.PurchaseOffers.maxPosition
					// 	)
					// 		settings.PurchaseOffers.maxPosition = settings.PurchaseOffers.minPosition + 0.1;
					// 	setSettingsMarket(settings);
					// }}
					min={0}
					max={1000}
					step={0.01}
					title='Min'
				/>
			</TableCell>

			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<TokenAmountShort
					value={0}
					// onChange={(newValue) => {
					// 	let settings = JSON.parse(JSON.stringify(settingsMarket));
					// 	settings.PurchaseOffers.minPosition = newValue;
					// 	if (
					// 		settings.PurchaseOffers.maxPosition < settings.PurchaseOffers.minPosition ||
					// 		!settings.PurchaseOffers.maxPosition
					// 	)
					// 		settings.PurchaseOffers.maxPosition = settings.PurchaseOffers.minPosition + 0.1;
					// 	setSettingsMarket(settings);
					// }}
					min={0}
					max={1000}
					step={0.01}
					title='Min'
				/>
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
			<AddCircleOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON,color:blue[500]}}/>
				<CheckCircleOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON,color:green[500]}}/>
				<DeleteOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON,color:red[700]}}/> 
			</TableCell>
		</TableRow>
	);
}

export default WalletPurchasesOffersBody;

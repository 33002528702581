import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Select, Box, Typography, Paper, MenuItem } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

function PiePropertyDiversity() {
	const { realTokens, settingsStats, setSettingsStats } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];
	const totalProperties = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0 || 0), 0));
	const totalCapital = parseInt(tokens.reduce((acc, data) => acc + (data.totalInvestment > 0 ? data.totalInvestment : 0 || 0), 0));

	const cityCounts = tokens.reduce((acc, token) => {
		const city = token.fullName.split(",")[1].trim();
		if (!acc[city]) acc[city] = { nbProperties: 1, capital: token.totalInvestment };
		else acc[city] = { nbProperties: acc[city].nbProperties + 1, capital: acc[city].capital + token.totalInvestment };
		// console.log("cityCounts", city, acc[city]);
		return acc;
	}, {});

	let data = Object.entries(cityCounts).map(([cityName, cityData]) => {
		return settingsStats.selectedCalculType === "RealTokens"
			? {
					nbProperties: cityData.nbProperties,
					value: (100 * cityData.nbProperties) / totalProperties,
					label: `${cityName} (${cityData.nbProperties})`,
			  }
			: {
					nbProperties: cityData.nbProperties,
					value: parseFloat(((100 * cityData.capital) / totalCapital).toFixed(1)),
					label: `${cityName} (${
						cityData.capital > 1000000 ? (cityData.capital / 1000000).toFixed(1) + "M$" : (cityData.capital / 1000).toFixed(1) + "k$"
					})`,
			  };
	});
	data = data.sort((a, b) => b.nbProperties - a.nbProperties || a.label.localeCompare(b.label));

	const setSettingsStatsChange = (key, newValue) => {
		// console.log("setSettingsStatsChange:", key, newValue);
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	// console.log("cityCounts:", cityCounts);
	// console.log("data:", data);

	const chartSetting = {
		width: settingsStats.selectedCalculType === "RealTokens" ? 760 : 860,
		height: 325,
	};

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 300, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Properties to
				</Typography>
				<Select
					sx={{ mr: 1, minWidth: 125 }}
					value={settingsStats.selectedCalculType}
					onChange={(e) => setSettingsStatsChange("selectedCalculType", e.target.value)}
					size='small'
				>
					{settingsStats.calculType.map((p, index) => (
						<MenuItem key={index} value={p}>
							{p}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Diversity Ratio
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<PieChart
					series={[
						{
							arcLabel: (item) => `${item.value.toFixed(1)}%`,
							arcLabelMinAngle: 15,
							data,
							innerRadius: 90,
							outerRadius: 140,
							paddingAngle: 0,
							cornerRadius: 5,
							startAngle: 0,
							endAngle: 360,
							cx: 155,
							cy: 160,
						},
						// (fontSize: 50),
					]}
					sx={{
						[`& .${pieArcLabelClasses.root}`]: {
							fill: "white",
							fontWeight: "bold",
						},
					}}
					slotProps={{
						legend: {
							labelStyle: {
								fontSize: 12,
							},
						},
					}}
					{...chartSetting}
				/>
			</Box>
		</Paper>
	);
}

export default PiePropertyDiversity;

import React, { useContext, useEffect, useState, useRef } from "react";
import { SettingsContext } from "../context/settings-context";
// import { Link } from "react-router-dom";
import { MyThemeContext } from "../context/theme-context";
import { RealTokensContext } from "../context/realTokens-context";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Typography, IconButton, Tabs, Tab, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TokenIcon from "@mui/icons-material/Token";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";


import PurchasesOffers from "../components/Market/PurchasesOffers";
import SalesOffers from "../components/Market/SalesOffers";
import DrawerComponent from "../components/Market/DrawerComponent";
import Header from "../components/Header/Header";
import LoadingWebSite from "../components/Common/LoadingWebSite";

const tabs = [
	{ id: 0, name: "Purchases", component: <PurchasesOffers />, icon: <TokenIcon sx={{ width: 45, height: 45 }} /> },
	{ id: 1, name: "Sales", component: <SalesOffers />, icon: <AccountBalanceWalletIcon sx={{ width: 45, height: 45 }} /> },
	// { id: 2, name: "Order book", component: <PurchasesOffers />, icon: <AccountBalanceWalletIcon sx={{ width: 45, height: 45 }} /> },
];

const TITLE = "Pit's BI - Market";

function Market() {
	const { settingsLoaded } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const { setToUpdateTask } = useContext(RealTokensContext);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = TITLE;
	}, []);

	useEffect(() => {
		// Lire le tabValue depuis l'URL et le définir
		const params = new URLSearchParams(location.search);
		const tabFromUrl = params.get("tab");
		if (tabFromUrl !== null) {
			setTabValue(parseInt(tabFromUrl, 10));
		} else {
			navigate(`/market?tab=1`);
		}
	}, [location.search]);

	const handleTabClick = (index) => {
		setTabValue(index);
		navigate(`/market?tab=${index}`);
	};

	// -------------------------------------------------------
	// ---- Timer pour mise à jour des prix des tokens    ----
	// -------------------------------------------------------

	const TIMEOUT_REFRESH = 15 * 1000; // Timer pour la lecture des offres quand la page est active
	const [state, setState] = useState({ num: 0 });
	const counter = useRef(1);
	useEffect(() => {
		const addTask = (taskName) => {
			setToUpdateTask((prevTasks) => {
				const taskExists = prevTasks.some((task) => task.name === taskName);
				if (!taskExists) {
					const newTask = { name: taskName, timeStart: new Date().getTime(), timeStop: 0 };
					return [...prevTasks, newTask];
				}
				return prevTasks;
			});
		};

		addTask("update_Market");
		// console.log("addTask('update_Market');");

		counter.current += 1;
		const timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
		return () => clearTimeout(timer);
	}, [state]);

	// -------------------------------------------------------

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	return (
		<Box>
			<Header />
			<Box sx={{ marginBottom: isMatch ? 0 : 0 }}>
				<DrawerComponent key='MarketDrawer' openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} handleTabClick={handleTabClick} tabs={tabs} />
			</Box>
			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box>
					<Box sx={{ width: 1, height: "64px" }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
						{isMatch && (
							<IconButton color='inherit' onClick={() => setOpenDrawer(true)}>
								<MenuIcon />
								<Typography variant='h6' color='inherit' sx={{ ml: 1 }}>
									{tabs[tabValue].name}
								</Typography>
							</IconButton>
						)}
						{!isMatch && (
							<Tabs
								key='MarketTabs'
								value={tabValue}
								variant='fullWidth'
								textColor='inherit'
								indicatorColor='secondary'
								sx={{ width: 1, ml: "auto" }}
							>
								{tabs.map((tab, index) => (
									<Tab key={"Header"+index} tabIndex={index} label={tab.name} value={index} onClick={() => handleTabClick(index)} />
								))}
							</Tabs>
						)}
					</Box>
					<Box justifyContent={"center"}>{tabs.filter((tab, index) => index === tabValue).map((tab, index) => tab.component)}</Box>
				</Box>
			)}
		</Box>
	);
}

export default Market;

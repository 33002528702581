import React, { useContext, useState, useEffect, useRef } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { LineChart, BarChart } from "@mui/x-charts";
import { green, red } from "@mui/material/colors";
import FormatedPrice from "../../Common/Formater/FormatedPrice";

function WidgetRentCollected() {
	const { settingsWallet, settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
	const { realTokens, getNextRonday, holderWallets, balanceWallets } = useContext(SettingsContext);
	const [rents, setRents] = useState(null);

	const get_APIURL_RENT_HOLDER = async () => {
		try {
			let rentsAllWallets = [];

			// Utilisation de for...of pour itérer sur les wallets sélectionnés
			for (const holderWallet of holderWallets.filter((holderWallet) => holderWallet.checked)) {
				const APIURL_WHITELIST = "https://ehpst.duckdns.org/realt_rent_tracker/api/rent_holder/" + holderWallet.address;
				let response = await fetch(APIURL_WHITELIST);

				if (response.ok) {
					let body = await response.json();
					if (rentsAllWallets.length === 0) {
						rentsAllWallets = body;
					} else
						body.forEach((entry) => {
							const existingEntry = rentsAllWallets.find((a) => a.date === entry.date);
							if (existingEntry) {
								existingEntry.rent += entry.rent;
							} else {
								rentsAllWallets.push(entry);
							}
						});
				}
			}

			// Trier le tableau par date à la fin de la boucle
			rentsAllWallets.sort((a, b) => new Date(a.date) - new Date(b.date));

			// calcul du cumul des rents
			let cumulative = 0;
			const result = rentsAllWallets.map((entry) => {
				cumulative += entry.rent;
				return {
					...entry,
					cumulativeRent: parseFloat(cumulative.toFixed(2)), // arrondi à deux décimales
				};
			});
			result.length > 0 ? setRents(result) : setRents(null);
		} catch (error) {
			console.log("get_APIURL_RENT_HOLDER - fetch error:", error);
		}
	};

	useEffect(() => {
		setRents(null);
		if (holderWallets) {
			let nbSelectedWallets = holderWallets.filter((w) => w.checked).length;
			if (nbSelectedWallets === 0) setRents(null);
		}
	}, [holderWallets]);

	// -------------------------------------------------------
	// ---- Timer pour mise à jour des rentCollected    ----
	// -------------------------------------------------------

	const TIMEOUT_REFRESH = 1 * 60 * 60 * 1000; // Timer pour le refresh du traffic
	const TIMEOUT_REFRESH_FAST = 15 * 1000; // Timer pour le refresh du traffic
	const [state, setState] = useState({ num: 0 });
	const counter = useRef(1);
	useEffect(() => {
		const fetchData = async () => {
			if (holderWallets) await get_APIURL_RENT_HOLDER();
		};

		if (!rents) {
			if (counter.current > 2) fetchData();
			// console.log("Attende des rents collected !!!");
		}

		counter.current += 1;
		const timer = setTimeout(() => setState({ num: counter.current }), rents ? TIMEOUT_REFRESH : TIMEOUT_REFRESH_FAST);
		return () => clearTimeout(timer);
	}, [state, rents]);

	// -------------------------------------------------------

	const size_width = 500;
	const size_height = 325;
	const size_width_reinvest = 1000;

	// Condition pour vérifier si les données sont disponibles

	if (!settingsDashboard.isExpandedInsight) return <></>;
	if (!realTokens) return <></>;
	if (!holderWallets || holderWallets.length === 0) return <></>;
	if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
	if (settingsDashboard.selectedRentType === null) return <></>;
	const selectedRentType = settingsDashboard.selectedRentType;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];

	let nbSelectedWallets = holderWallets.filter((w) => w.checked).length;
	if (nbSelectedWallets === 0) return <></>;
	// if (!rents) return <></>;

	// Generation des interets composé pour les 10 prochaine années
	let tokensBalance = [
		{ label: "Ethereum", chain: "eth", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
		{ label: "Gnosis", chain: "xdai", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
		{ label: "RMM", chain: "rmm", count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
		{ label: "RMM v3", chain: "rmmv3", count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
		{ label: "Levinswap", chain: "pool", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
		{ label: "Total", chain: "total", count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
	];

	if (balanceWallets) {
		tokensBalance.forEach((tokenBalance) => {
			holderWallets
				.filter((holderWallet) => holderWallet.checked)
				.forEach((holderWallet) => {
					for (const wallet of Object.keys(balanceWallets)) {
						if (holderWallet.address === wallet) {
							for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
								if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
									if (chain === "eth") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
												if (realToken)
													if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(token.amount);
														tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
													}
											});
									}
								}

								if (tokenBalance.chain === "xdai" || tokenBalance.chain === "total") {
									if (chain === "xdai") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
												if (realToken)
													if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
														// if (Date.now()-1000*60*60*24*0 > getNextRonday(realToken).getTime())
														// 	console.log("WidgetRents", getNextRonday(realToken),realToken.shortName,token.amount);
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(token.amount);
														tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
													}
											});
									}
								}

								if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
									if (chain === "rmm") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												if (token.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
													const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
													if (realToken)
														if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
															if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.nbToken += parseFloat(rmmBalance);
															tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
														}
												}
											});
									}
								}

								if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
									if (chain === "rmmv3") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
												if (realToken)
													if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
														if (selectedRentType === "Day") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === "Week") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === "Month") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === "Year") tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(rmmBalance);
														tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
													}
											});
									}
								}

								if (tokenBalance.chain === "pool" || tokenBalance.chain === "total") {
									if (chain === "pool") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												// console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
												const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
												const totalSupply = parseFloat(token.pair.totalSupply);
												if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
													if (realToken)
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token0.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = realtNbToken * (poolBalance / holderNbToken);
															if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
																if (selectedRentType === "Day")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === "Week")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === "Month")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === "Year")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbToken += parseFloat(poolBalance);
																tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
															}
														}
												}
												if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
													if (realToken)
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token1.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = realtNbToken * (poolBalance / holderNbToken);
															if (Date.now() > getNextRonday(realToken).getTime() && (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)) {
																if (selectedRentType === "Day")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === "Week")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === "Month")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === "Year")
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbToken += parseFloat(poolBalance);
																tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
															}
														}
												}
											});
									}
								}
							}
						}
					}
				});
		});
	}
	let nextRonday = new Date(Date.now());
	// console.log("nextRonday", nextRonday);
	// console.log("tokensBalance");
	// console.table(tokensBalance[5]);
	// if (rents !== null) {
	// 	console.log("rents");
	// 	console.table(rents.slice(-1)[0]);
	// 	console.log(rents.slice(-1)[0].cumulativeRent);
	// }

	const endDate = new Date(nextRonday);
	endDate.setMonth(endDate.getMonth() + 20 * 12);

	// console.log("Traces PITOT", nextRonday, endDate);

	function generateComposedInterest(startDate, endDate) {
		const dateList = [];
		const holderInvest = tokensBalance[5];
		let currentDate = new Date(startDate);

		// console.log("start generateComposedInterest");

		//parametre
		let ratioRe = 1; // Ratio de reinvestissement des rents
		let weekInvest = 300; // Calcul du capital Total

		let capital = holderInvest.total; // Calcul du capital de depart sans Re Invest
		let capitalR = holderInvest.total; // Calcul du capital avec Re Invest
		let capitalWR = holderInvest.total; // Calcul du capital de la part Invest
		if (rents) capitalWR = holderInvest.total - rents.slice(-1)[0].cumulativeRent * ratioRe;
		let rentsW = holderInvest.count; // Calcul des rents/semaines
		if (rents) rentsW = rents.slice(-1)[0].rent;
		let rentsY = holderInvest.rent; // Calcul des rents/années
		let rentsC = 0;
		if (rents) rentsC = rents.slice(-1)[0].cumulativeRent; // Calcul des rents sur capicumulées
		let yieldRatio = holderInvest.yield; // Calcul du ratio
		let yieldCI = rentsY / capitalWR; // Calcul du ratio

		while (currentDate <= endDate) {
			dateList.push({
				date: new Date(currentDate),
				capitalR: capitalR,
				capital: capital,
				capitalWR: capitalWR,
				rentsC: rentsC,
				rentsW: rentsW,
				rentsY: rentsY,
				rentsC: rentsC,
				yield: yieldRatio,
				yieldCI: yieldCI,
			});
			// Incrément de 7 jours
			currentDate.setDate(currentDate.getDate() + 7);
			capitalR += rentsW * ratioRe + weekInvest;
			capital += weekInvest;
			rentsC += rentsW;
			rentsY = capitalR * yieldRatio;
			rentsW = rentsY / 52;
			capitalWR += rentsW * ratioRe + weekInvest;
		}

		return dateList;
	}

	if (!rents && false) {
		return (
			<Paper elevation={3} sx={{ m: 1, width: size_width, minHeight: size_height, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
						Rents Collected
					</Typography>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: size_height }}>
					<CircularProgress size='4rem' />
					<Typography variant='body2' size='4rem' color='primary.main' sx={{ mt: 2 }}>
						<em>Loading collected Rents</em>
					</Typography>
				</Box>
			</Paper>
		);
	}

	const composedInterest = generateComposedInterest(nextRonday, endDate);
	// console.log("composedInterest");
	// console.table(composedInterest);

	let configRentCollectedChart = null;
	if (rents) {
		configRentCollectedChart = {
			series: [
				{ data: rents.map((x) => parseFloat(x.cumulativeRent)), label: "Cumulated Rents", showMark: false, yAxisId: "leftAxisId" },
				{ data: rents.map((x) => parseFloat(x.rent)), label: "Rents", showMark: false, yAxisId: "rightAxisId" },
			],
			margin: { top: 5, bottom: 27, right: 50, left: 60 },
		};
	}

	const configReInvestChart = {
		series: [
			{ data: composedInterest.map((x) => parseFloat(x.capitalR)), label: "Capital ReInvest", showMark: false, yAxisId: "leftAxisId" },
			{ data: composedInterest.map((x) => parseFloat(x.capital)), label: "Capital", showMark: false, yAxisId: "leftAxisId" },
			// { data: composedInterest.map((x) => parseFloat(x.capitalWR)), label: "Capital Invested", showMark: false, yAxisId: "leftAxisId" },
			{ data: composedInterest.map((x) => parseFloat(x.rentsC)), label: "Rents Collected", showMark: false, yAxisId: "leftAxisId" },
			{ data: composedInterest.map((x) => parseFloat(x.rentsW)), label: "Week Rents", showMark: false, yAxisId: "rightAxisId" },
		],
		margin: { top: 5, bottom: 27, right: 50, left: 75 },
	};

	return (
		<>
			{!rents ? (
				<Paper elevation={3} sx={{ m: 1, width: size_width, minHeight: size_height, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
						<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
							Rents Collected
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: size_height }}>
						<CircularProgress size='4rem' />
						<Typography variant='body2' size='4rem' color='primary.main' sx={{ mt: 2 }}>
							<em>Loading collected Rents</em>
						</Typography>
					</Box>
				</Paper>
			) : (
				<Paper elevation={3} sx={{ m: 1, width: size_width, minHeight: size_height, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
						<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
							Rents Collected
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: size_height }}>
						<LineChart
							xAxis={[{ data: rents.map((x) => new Date(x.date)), scaleType: "time", tickMinStep: 3600 * 1000 * 24 * 7 }]}
							yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
							{...configRentCollectedChart}
							slotProps={{ legend: { hidden: false } }}
							leftAxis='leftAxisId'
							rightAxis='rightAxisId'
							axisHighlight={{ x: "line", y: "line" }}
						/>
					</Box>
				</Paper>
			)}

			<Paper elevation={3} sx={{ m: 1, width: size_width_reinvest, minHeight: size_height, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
						Compound Interest <em> (settings SOON, 100% ReInvest & 300$/Week !)</em>
					</Typography>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: size_height }}>
					<LineChart
						xAxis={[{ data: composedInterest.map((x) => new Date(x.date)), scaleType: "time", tickMinStep: 3600 * 1000 * 24 * 7 }]}
						yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
						{...configReInvestChart}
						slotProps={{ legend: { hidden: false } }}
						leftAxis='leftAxisId'
						rightAxis='rightAxisId'
						axisHighlight={{ x: "line", y: "line" }}
					/>
				</Box>
			</Paper>
		</>
	);
}

export default WidgetRentCollected;

import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from "@mui/material";
import { green, red } from "@mui/material/colors";
import FormatedPrice from "../../Common/Formater/FormatedPrice";

function WidgetWallets() {
	const { realTokens, holderWallets, balanceWallets } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
	];

	let tokensBalance = [
		{ label: "Ethereum", chain: "eth", count: 0, nbToken: 0, tokens: [] },
		{ label: "Gnosis", chain: "xdai", count: 0, nbToken: 0, tokens: [] },
		{ label: "RMM", chain: "rmm", count: 0, nbToken: 0, tokens: [] },
		{ label: "RMM v3", chain: "rmmv3", count: 0, nbToken: 0, tokens: [] },
		{ label: "Levinswap", chain: "pool", count: 0, nbToken: 0, tokens: [] },
		{ label: "Total", chain: "total", count: 0, nbToken: 0, tokens: [] },
	];

	if (!holderWallets || !holderWallets.length > 0)
		return (
			<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: red[700], borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
						No wallet configured!
					</Typography>
				</Box>
			</Paper>
		);

	if (balanceWallets) {
		tokensBalance.forEach((tokenBalance) => {
			holderWallets
				.filter((holderWallet) => holderWallet.checked)
				.forEach((holderWallet) => {
					for (const wallet of Object.keys(balanceWallets)) {
						if (holderWallet.address === wallet) {
							for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
								if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
									if (chain === "eth") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
												if (realToken) tokenBalance.count += parseFloat(token.amount * realToken.tokenPrice);
											});
									}
								}

								if (tokenBalance.chain === "xdai" || tokenBalance.chain === "total") {
									if (chain === "xdai") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
												if (realToken) {
													// console.log("WidgetWallets - holderWallet", holderWallet, chain, token);
													tokenBalance.count += parseFloat(token.amount * realToken.tokenPrice);
												}
											});
									}
								}

								if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
									if (chain === "rmm") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
												if (realToken) tokenBalance.count += parseFloat(rmmBalance * realToken.tokenPrice);
											});
									}
								}

								if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
									if (chain === "rmmv3") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
												if (realToken) tokenBalance.count += parseFloat(rmmBalance * realToken.tokenPrice);
											});
									}
								}

								if (tokenBalance.chain === "pool" || tokenBalance.chain === "total") {
									if (chain === "pool") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												// console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
												const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
												const totalSupply = parseFloat(token.pair.totalSupply);
												if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
													if (realToken)
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token0.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
															tokenBalance.count += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.nbToken += 1;
														} else console.log(realToken.shortName, realToken.pool);
												}
												if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
													if (realToken)
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token1.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
															tokenBalance.count += parseFloat(poolBalance * realToken.tokenPrice * 2);
															tokenBalance.nbToken += 1;
														} else console.log(realToken.shortName, realToken.pool);
												}
											});
									}
								}
							}
						}
					}
				});
		});
	}
	// console.log("WidgetWallets - tokensBalance", tokensBalance);
  // console.log("WidgetWallets - holderWallet",  tokens);

	if (!tokensBalance[5].count > 0)
		return (
			<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						The selected wallet(s) are empty.
					</Typography>
				</Box>
			</Paper>
		);
	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 150, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Wallets
				</Typography>
			</Box>
			<Box>
				<TableContainer>
					<Table
						sx={{
							minWidth: 200,
							background: "background.paper",
							"& thead th": { borderWidth: 0 },
							"& tbody tr:last-child td": { borderBottom: 0 },
						}}
						aria-label='sticky table'
					>
						<TableBody sx={{ mt: 0 }}>
							{tokensBalance
								.filter((token) => token.count > 0)
								.map((token) => (
									<TableRow key={token.label}>
										<TableCell align='left' sx={{ px: 2, py: 0.5 }}>
											<Typography
												variant='body2'
												component='span'
												style={{ fontWeight: token.chain === "total" ? "bold" : "normal" }}
												sx={{ color: token.chain === "total" ? "primary.main" : "inherit" }}
											>
												{token.label}
											</Typography>
										</TableCell>
										<TableCell align='right' sx={{ px: 2, py: 0.5 }}>
											<FormatedPrice
												value={token.count}
												variant={"body2"}
												color={token.chain === "total" ? "primary.main" : "inherit"}
												fontWeight={token.chain === "total" ? "bold" : "normal"}
											/>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	);
}

export default WidgetWallets;

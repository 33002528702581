import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from "@mui/material";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
// import { useTheme } from "@mui/system";

function TableInvestment({ defaultPropertyType = 0 }) {
	const { realTokens } = useContext(SettingsContext);

	if (!realTokens) return <></>;
	let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];
	if (defaultPropertyType > 0) tokens=tokens.filter((token) => token.propertyType === defaultPropertyType);
	// const tokens = tokenslist.map((t) => {
	// 	if (t.totalUnits === 4 && t.propertyType === 2) {
	// 		console.log("change propertyType", t.shortName, t.totalUnits, t.propertyType);
	// 		t.propertyType = 8;
	// 	}
	// 	return t;
	// });

	// const totaltotalInvestment = parseInt(tokens.reduce((acc, data) => acc + (data.totalInvestment || 0), 0));
	const totaltotalInvestment = parseInt(tokens.reduce((acc, data) => acc + (data.historic.init_price * data.totalTokens || 0), 0));
	const totalGrossRentYear = parseInt(tokens.reduce((acc, data) => acc + (data.grossRentYear || 0), 0));
	const totalNetRentYear = parseInt(tokens.reduce((acc, data) => acc + (data.netRentYear || 0), 0));

	// console.log("totalGrossRentYear", totalGrossRentYear);

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Investment
				</Typography>
			</Box>
			<Box>
				<TableContainer>
					<Table
						sx={{
							minWidth: 300,
							background: "background.paper",
							"& thead th": { borderWidth: 0 },
							"& tbody tr:last-child td": { borderBottom: 0 },
						}}
						aria-label='sticky table'
					>
						<TableBody sx={{ mt: 0 }}>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Investments
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={totaltotalInvestment} variant={"body1"} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Gross Rent/Year
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={totalGrossRentYear} variant={"body1"} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span'>
										Net Rent/Year
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPrice value={totalNetRentYear} variant={"body1"} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left' sx={{ px: 2, py: 1.5 }}>
									<Typography variant='body1' component='span' style={{ fontWeight: "bold" }} sx={{ color: "primary.main" }}>
										RealT Yield
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ px: 2, py: 1.5 }}>
									<FormatedPercentage value={totalNetRentYear / totaltotalInvestment} variant={"body1"} color={"primary.main"} fontWeight={"bold"} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	);
}

export default TableInvestment;

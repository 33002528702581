import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { SnackbarContext } from "../../context/customsnackbar-context";
import { MyThemeContext } from "../../context/theme-context";

import { Badge, Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { green } from "@mui/material/colors";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import PurchasesOffersHead from "./PurchasesOffers/PurchasesOffersHead";
import WalletPurchasesOffersHead from "./PurchasesOffers/WalletPurchasesOffersHead";
// import TokensTableHead from "./PurchasesOffers/TokensTableHead";

function PurchasesOffers() {
	const { realTokens, holderWallets, balanceWallets, blockchainsClient } = useContext(SettingsContext);
	const { settingsMarket, setSettingsMarket, settingsWallet } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const [tokens, setTokens] = useState(null);
	const [allOffers, setAllOffers] = useState(true);
	const [walletOffers, setWalletOffers] = useState(false);
	const [helpOffers, setHelpOffers] = useState(false);
	const [shopping, setShopping] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	useEffect(() => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (!settings.PurchaseOffers) {
			settings.PurchaseOffers = { selectedPropertyType: 0, unselectedCity: [], showRentStart: true, showPosition: true, showRented: true };
			setSettingsMarket(settings);
		}
	}, [settingsMarket]);

	useEffect(() => {
		if (realTokens) {
			let cptTokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
			];
			if (settingsMarket.PurchaseOffers)
				if (settingsMarket.PurchaseOffers.selectedPropertyType > 0)
					cptTokens = cptTokens.filter((token) => token.propertyType === settingsMarket.PurchaseOffers.selectedPropertyType);

			if (balanceWallets) {
				const testContract = (token, address) => {
					if (token.ethereumContract === address || token.gnosisContract === address || token.xDaiContract === address) return true;
					else return false;
				};

				cptTokens.forEach((token) => {
					token.walletsAmount = 0;
					token.whiteList = false;
					token.strRentStartDate = token.rentStartDate.date.slice(0, 10);
					if (holderWallets) {
						if (holderWallets.filter((holderWallet) => holderWallet.checked)[0]) {
							holderWallets
								.filter((holderWallet) => holderWallet.checked)
								.forEach((holderWallet) => {
									balanceWallets[holderWallet.address].tokens["xdai"].tokens
										.filter((t) => testContract(token, t.token.id))
										.forEach((t) => {
											// console.log("token to find", t);
											token.walletsAmount += parseFloat(t.amount);
											// console.log("Add xdai wallets token", token.shortName, token.walletsAmount);
										});
									balanceWallets[holderWallet.address].tokens["eth"].tokens
										.filter((t) => testContract(token, t.token.id))
										.forEach((t) => {
											token.walletsAmount += parseFloat(t.amount);
										});
									if (balanceWallets[holderWallet.address].tokens["rmm"].tokens) {
										balanceWallets[holderWallet.address].tokens["rmm"].tokens
											.filter((t) => testContract(token, t.reserve.underlyingAsset))
											.forEach((t) => {
												token.walletsAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
											});
									}
									if (balanceWallets[holderWallet.address].tokens["rmmv3"].tokens) {
										balanceWallets[holderWallet.address].tokens["rmmv3"].tokens
											.filter((t) => testContract(token, t.token.id))
											.forEach((t) => {
												token.walletsAmount += parseFloat(t.amount / 10 ** t.token.decimals);
											});
									}
									// if (balanceWallets[holderWallet.address].tokens["pool"].tokens) {
									// 	balanceWallets[holderWallet.address].tokens["pool"].tokens
									// 		.filter((t) => testContract(token, t.pair.token0.id))
									// 		.forEach((t) => {
									// 			token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
									// 			// console.log("Add pool0 wallets token", token.shortName, token.walletsAmount);
									// 		});
									// 	balanceWallets[holderWallet.address].tokens["pool"].tokens
									// 		.filter((t) => testContract(token, t.pair.token1.id))
									// 		.forEach((t) => {
									// 			token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
									// 			// console.log("Add pool1 wallets token", token.shortName, token.walletsAmount);
									// 		});
									// }
									if (balanceWallets[holderWallet.address].whiteList.tokens) {
										balanceWallets[holderWallet.address].whiteList.tokens
											.filter((t) => testContract(token, t))
											.forEach((t) => {
												token.whiteList = true;
											});
									}
								});
						}
					}
					// console.log("Add TOTAL wallets token", token.shortName, token.walletsAmount);
				});
				setTokens(cptTokens);
			}
		}
	}, [realTokens, settingsMarket]);

	if (!realTokens) return <></>;
	if (!holderWallets) return <></>;
	if (!balanceWallets) return <></>;
	if (!tokens) return <></>;
	if (!settingsMarket.PurchaseOffers) return <></>;
	if (!settingsMarket.SwapCatOffersList)
		return (
			<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting SwapCat & YAM Offers synchronisation ...
					</Typography>
				</Box>
			</Paper>
		);
	if (!settingsMarket.YamOffersList)
		return (
			<>
				<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
						<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
							Waiting SwapCat & YAM Offers synchronisation ...
						</Typography>
					</Box>
				</Paper>
			</>
		);

	// console.log("tokens", tokens);

	let offers_swapcat = settingsMarket.SwapCatOffersList.filter((offer) => offer.token_to_buy !== null && offer.token_to_pay !== null);
	offers_swapcat = offers_swapcat.map((offer) => {
		return {
			...offer,
			sc: "swapcat",
			token: tokens ? tokens.filter((token) => token.gnosisContract === offer.token_to_buy)[0] : null,
		};
	});
	let offers_yam = settingsMarket.YamOffersList.filter(
		(offer) => offer.token_to_buy !== null && offer.token_to_pay !== null && offer.buy_holder_address === "0x0000000000000000000000000000000000000000"
	);
	offers_yam = offers_yam.map((offer) => {
		return {
			...offer,
			sc: "yam",
			token: tokens ? tokens.filter((token) => token.gnosisContract === offer.token_to_buy)[0] : null,
		};
	});
	let offers = offers_swapcat.concat(offers_yam);
	// console.log("offers_swapcat", offers_swapcat.length, offers_swapcat[0]);
	// console.log("offers_yam", offers_yam.length, offers_yam[0]);

	const cityList = tokens
		.reduce((acc, token) => {
			const parts = token.fullName.split(",").map((part) => part.trim());
			let city, countryLabel;

			if (parts.length === 3 || parts.length === 4) {
				city = parts[1];
				countryLabel = parts.length === 3 ? "USA" : parts[3]; // Determine the country label
			} else {
				console.log("Create CityList error: ", token.fullName);
				city = "Error";
				countryLabel = "Error";
			}
			let cityEntry = acc ? acc.find((entry) => entry.label === city) : null;
			if (!cityEntry) {
				cityEntry = { id: acc.length, count: 0, label: city, checked: true };
				acc.push(cityEntry);
			}
			cityEntry.count += 1;
			return acc;
		}, [])
		.sort((a, b) => {
			return a.label.localeCompare(b.label);
		});

	// ------------------------------------------------------------
	// -- Calculate and filter 'offers' for PourchasesOffersHead --
	// ------------------------------------------------------------
	{
		offers.forEach((offer) => {
			if (offer.token) {
				offer.shortName = offer.token.shortName;
				offer.fullName = offer.token.fullName;
				offer.city = offer.token.fullName.split(",").map((part) => part.trim())[1];
				offer.tokenPrice = offer.token.tokenPrice;
				offer.position = offer.token.walletsAmount;
				offer.marketplaceLink = offer.token.marketplaceLink;
				offer.rentStartDate = offer.token.rentStartDate.date.slice(0, 10);
				offer.annualPercentageYield = offer.token.annualPercentageYield;
				offer.whiteList = offer.token.whiteList;

				offer.token_value_usd = 0;
				offer.token_to_pay_img = "./images/unknown.png";
				offer.token_to_pay_name = "unknown ???";
				const tokenToPaid = Object.values(blockchainsClient[settingsWallet.selectedBlockchain].coinList).find(
					(coin) => coin.address.toLowerCase() === offer.token_to_pay.toLowerCase()
				);
				// if (!tokenToPaid) console.log("tokenToPaid unKnown ?", offer.token_to_pay);
				if (tokenToPaid) {
					offer.token_to_pay_img = tokenToPaid.imgToken;
					offer.token_to_pay_name = tokenToPaid.name;
					offer.token_value_usd = offer.token_value * tokenToPaid.toUSD;
				}
				offer.token_ratio = (offer.token_value_usd / offer.tokenPrice) * 1;
				offer.token_newYield = (offer.annualPercentageYield * offer.tokenPrice) / offer.token_value_usd / 100;
				offer.token_rented = offer.token.rentedUnits / offer.token.totalUnits;
				offer.last_pricing_days = -1;
				offer.nb_pricing = 0;
				if (offer.token.historic) {
					offer.nb_pricing = offer.token.historic.prices.length - 1;
					const prisingDate = offer.token.historic.prices[offer.token.historic.prices.length - 1].timsync;
					const prisingDateObj = new Date(prisingDate);
					const currentDate = new Date();
					const timeDifference = currentDate - prisingDateObj;
					const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
					offer.last_pricing_days = daysDifference;
					if (offer.last_pricing_days < 0) offer.last_pricing_days = -1;
				}
			}
		});
		offers = offers.filter((offer) => offer.token_value_usd > 0);
		if (settingsMarket.PurchaseOffers.unselectedCity)
			offers = offers.filter((offer) => {
				const city = offer.fullName.split(",")[1].trim();
				return !settingsMarket.PurchaseOffers.unselectedCity.includes(city);
			});
		if (settingsMarket.PurchaseOffers.isPosition)
			offers = offers.filter(
				(offer) => offer.position >= settingsMarket.PurchaseOffers.minPosition && offer.position < settingsMarket.PurchaseOffers.maxPosition
			);
		if (settingsMarket.PurchaseOffers.isOfferAmount)
			offers = offers.filter(
				(offer) =>
					offer.token_amount >= settingsMarket.PurchaseOffers.minOfferAmount && offer.token_amount < settingsMarket.PurchaseOffers.maxOfferAmount
			);
		if (settingsMarket.PurchaseOffers.isDiffPrice)
			offers = offers.filter(
				(offer) =>
					offer.token_ratio * 100 >= settingsMarket.PurchaseOffers.minDiffPrice &&
					offer.token_ratio * 100 < settingsMarket.PurchaseOffers.maxDiffPrice
			);
		if (settingsMarket.PurchaseOffers.isRealtPrice)
			offers = offers.filter(
				(offer) =>
					offer.annualPercentageYield >= settingsMarket.PurchaseOffers.minRealtPrice &&
					offer.annualPercentageYield < settingsMarket.PurchaseOffers.maxRealtPrice
			);
		if (settingsMarket.PurchaseOffers.isRealTYield)
			offers = offers.filter(
				(offer) =>
					offer.annualPercentageYield >= settingsMarket.PurchaseOffers.minRealTYield &&
					offer.annualPercentageYield < settingsMarket.PurchaseOffers.maxRealTYield
			);
		if (settingsMarket.PurchaseOffers.isNewPrice)
			offers = offers.filter(
				(offer) =>
					offer.token_value_usd >= settingsMarket.PurchaseOffers.minNewPrice &&
					offer.token_newYield * 100 < settingsMarket.PurchaseOffers.maxNewPrice
			);
		if (settingsMarket.PurchaseOffers.isNewYield)
			offers = offers.filter(
				(offer) =>
					offer.token_newYield * 100 >= settingsMarket.PurchaseOffers.minNewYield &&
					offer.token_newYield * 100 < settingsMarket.PurchaseOffers.maxNewYield
			);
		if (settingsMarket.PurchaseOffers.isRented)
			offers = offers.filter(
				(offer) =>
					offer.token_rented * 100 >= settingsMarket.PurchaseOffers.minRented && offer.token_rented * 100 < settingsMarket.PurchaseOffers.maxRented
			);
		if (settingsMarket.PurchaseOffers.isLastPricingDays)
			offers = offers.filter(
				(offer) =>
					offer.last_pricing_days >= settingsMarket.PurchaseOffers.minLastPricingDays &&
					offer.last_pricing_days < settingsMarket.PurchaseOffers.maxLastPricingDays
			);			
		if (settingsMarket.PurchaseOffers.isNbPricing)
			offers = offers.filter(
				(offer) => offer.nb_pricing >= settingsMarket.PurchaseOffers.minNbPricing && offer.nb_pricing < settingsMarket.PurchaseOffers.maxNbPricing
			);
		if (settingsMarket.PurchaseOffers.isRentStart) offers = offers.filter((offer) => new Date(offer.rentStartDate).getTime() <= new Date().getTime());
		if (settingsMarket.PurchaseOffers.isnotRentStart)
			offers = offers.filter((offer) => new Date(offer.rentStartDate).getTime() >= new Date().getTime());
		if (settingsMarket.PurchaseOffers.isWhiteList) offers = offers.filter((offer) => offer.whiteList);
	}
	// ------------------------------------------------------------
	// -- Calculate and filter 'offers' for PourchasesOffersHead --
	// ------------------------------------------------------------
	let allWalletOffers = JSON.parse(JSON.stringify(tokens));
	{
		allWalletOffers.forEach((offer) => {
			offer.position = offer.walletsAmount;
			offer.rentStartDate = offer.rentStartDate.date.slice(0, 10);
		});
	}
	// console.log("offers", offers.length, offers[1]);
	// console.log("cityList", cityList);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<Box
				sx={{
					width: "auto",
					// flexGrow: 0.1,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 2,
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "center",
				}}
			>
				<StorefrontIcon
					color={allOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 0, fontSize: allOffers ? 40 : 25 }}
					onClick={() => {
						setAllOffers(true);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(false);
					}}
				/>

				<AccountBalanceWalletOutlinedIcon
					color={walletOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 2, fontSize: walletOffers ? 40 : 25 }}
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(true);
						setHelpOffers(false);
						setShopping(false);
					}}
				/>

				<InfoOutlinedIcon
					color={helpOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 2, fontSize: helpOffers ? 40 : 25 }}
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(true);
						setShopping(false);
					}}
				/>

				<Badge
					sx={{ ml: 1, mt: 5 }}
					badgeContent={0}
					color='success'
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(true);
					}}
				>
					<ShoppingCartIcon
						color={shopping ? "primary" : "disabled"}
						large
						sx={{ fontSize: shopping ? 40 : 25 }}
						onClick={() => {
							setAllOffers(false);
							setWalletOffers(false);
							setHelpOffers(false);
							setShopping(true);
						}}
					/>
				</Badge>
			</Box>

			{allOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					<PurchasesOffersHead offers={offers} cityList={cityList} />
				</Box>
			)}

			{walletOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					<WalletPurchasesOffersHead offers={allWalletOffers} cityList={cityList} />
				</Box>
			)}

			{helpOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					helpOffers <br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}

			{shopping && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					shopping <br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}
		</Box>
	);
}

export default PurchasesOffers;

import React, { useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

function LoadingWebSite() {
	const [countdown, setCountdown] = useState(0);
	useEffect(() => {
		let interval;
		let timeout = 1 * 1000;
		if (countdown > 9999) setCountdown(0);
		interval = setInterval(() => {
			// Insérer vos fonctions à exécuter à échéance de timeout
			setCountdown((currentCountdown) => currentCountdown + 1);
		}, timeout);

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [countdown]);

	const now = Date.now(); // Milliseconds since January 1, 1970, 00:00:00 UTC
	const currentDate = new Date(now); // Convert milliseconds into a Date object

	return (
		<Box  sx={{ p: 15,display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
			<CircularProgress size='2rem' />
			<Typography variant='h3' color='inherit' sx={{ ml: 1 }}>
				Loading & Verify Settings
			</Typography>
			<Typography variant='h4' color='inherit' sx={{ ml: 1 }}>
				{currentDate.toLocaleDateString()} - {currentDate.toLocaleTimeString()}
			</Typography>
		</Box>
	);
}

export default LoadingWebSite;
